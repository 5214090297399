<template>
  <div id="app">

    <h1 class="text-center mb-4" style="white-space:pre-wrap; word-wrap:break-word;">{{msg.slogan}}</h1>

    <v-container>
      <v-row>

        <v-col cols="12">
          <h3>{{msg.event_list_reccomend}}</h3>
        </v-col>
        <!-- イベントs -->
        <events-list-view :events="recommend_events_collection.events"></events-list-view>

      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col cols="12">
          <h3>{{msg.event_list}}</h3>
        </v-col>

          <v-container fluid>
            <v-layout row wrap>
              <v-flex xs12 md4 class="px-3">
                <v-select :label="msg.search_prefecture" :items="master.prefectures" item-text="name" item-value="id" v-model="query.prefecture_id"></v-select>
              </v-flex>
              <v-flex xs12 md4 class="px-3">
                <v-select :label="msg.search_language" :items="master.languages" item-text="name" item-value="id" v-model="query.used_language_id"></v-select>
              </v-flex>
              <v-flex xs12 md4 class="px-3">
                <v-select :label="msg.search_purpose" :items="master.event_purposes" item-text="name" item-value="id" v-model="query.event_purpose_id"></v-select>
              </v-flex>
              <!-- イベントs -->
              <events-list-view :events="searched_events_collection.events"></events-list-view>
            </v-layout>
          </v-container>

      </v-row>
    </v-container>

    <v-container>
      <v-row>

        <v-col cols="12">
          <h3>{{ msg.past_event_list }}</h3>
        </v-col>
        <!-- イベントs -->
        <events-list-view :events="past_famous_events_collection.events"></events-list-view>

      </v-row>
    </v-container>

  </div>
</template>

<script type="text/javascript">
import axios from "@/store/axios"
import Event from '@/models/event'
import EventsCollection from '@/models/events_collection'
import Master from '@/models/master'
import messages from '@/locales/top.json'
import EventsListView from '@/components/shared/views/events_list_view'

export default {
  components: {
    'events-list-view':EventsListView,
  },
  data: function () {
    return {
      all_events_collection: new EventsCollection('/api/v1/events'),
      past_events_collection: new EventsCollection('/api/v1/events/past_events'),
      query: {
        prefecture_id: null,
        used_language_id: null,
        event_purpose_id: null
      },
      master: new Master(),
      is_loading_search: false,
    }
  },
  computed: {
    user() { return this.$store.state.user },
    msg() { return messages[this.$store.state.user.language] },
    searched_events_collection() { return this.all_events_collection.where(this.query).sort_by_date() },
    recommend_events_collection() { return this.all_events_collection.not_created_by_hf().sort_by_date() },
    past_famous_events_collection() { return this.past_events_collection }
  },
  created(){
    this.$store.commit('header_state/showSelection')
  }
}

</script>

<style scoped>
</style>
