<template id="">
  <v-container>
    <v-row>
      <v-col cols="12" md="10" offset-md="1">


        <!-- とりあえず大枠をつくった -->
        <v-container>
          <h2 class="text-center my-10">{{ msg.slogan }}</h2>

            <v-row>
              <v-col cols="12" class="mb-10">
                <h3 class="mb-4">{{ msg.why_we_started }}</h3>
                <h4>{{ msg.explanation }}</h4>
                <p class="mt-4 body-2">{{ msg.future_view }}</p>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="mb-5">
                <h2>{{ msg.our_members}}</h2>
              </v-col>
              <!-- メンバーの説明カードs -->
              <v-col cols="12" md="4" v-for="member in members" class="px-5">
                <v-img :src="member.image_path" max-width="100%" class="membericon"></v-img>
                <h3>{{ member.name }}</h3>
                <p>
                  <template v-for="ex in member.explanations">
                    {{ex}}<br>
                  </template>
                </p>
              </v-col>
            </v-row>


            <v-row>
              <v-col cols="12" class="mt-10">
                <h2 class="mb-3">{{ msg.contact }}</h2>
                <p>
                  {{msg.contact_p}} <br>
                  <v-icon>email</v-icon> <a href="mailto:contact@univents.jp" class="font-weight-bold">contact@univents.jp</a>
                </p>
              </v-col>
            </v-row>

            <!-- <v-row>
              <v-col cols="12" class="mt-10">
                <h2>{{ msg.news }}</h2>
              </v-col>
            </v-row> -->

        </v-container>



      </v-col>
    </v-row>
  </v-container>
</template>
<script type="text/javascript">
import messages from '@/locales/about_us.json'

export default{
  data(){
    return {
      members: [
        {name: "Juliette", image_path: "https://univents.s3-ap-northeast-1.amazonaws.com/manually/for_static_page/juliette.JPG", explanations: ["Founder", "Nagoya University", "Grad. School of Engineering"]},
        {name: "Masa", image_path: "https://univents.s3-ap-northeast-1.amazonaws.com/manually/for_static_page/masa.png", explanations: ["Founder", "Haromny For"]},
        {name: "Nasu", image_path: "https://univents.s3-ap-northeast-1.amazonaws.com/manually/for_static_page/nasu.jpg", explanations: ["Founder, Engineer", "Nagoya University", "School of Engineering"]},
        {name: "Hide", image_path: "https://univents.s3-ap-northeast-1.amazonaws.com/manually/for_static_page/hide.jpg", explanations: ["Engineer", "Nagoya University", "School of Informatics"]},
        {name: "Shalini", image_path: "https://univents.s3-ap-northeast-1.amazonaws.com/manually/for_static_page/shalini.jpg", explanations: ["Planning", "Nagoya University", "School of Law"]},
      ]
    }
  },
  computed:{
    msg() { return messages[this.$store.state.user.language] },
  },
}

</script>

<style scoped>
.membericon {
  border-radius: 50%;
}
</style>
