<template>
  <v-container fluid>
    <v-row>
      <v-text-field :label="msg.text_question" v-model="question.language_apply_question(lang_id).title"></v-text-field>
    </v-row>
    <v-row>
      <v-textarea disabled :placeholder="msg.long_text"></v-textarea>
    </v-row>
  </v-container>
</template>

<script>
import messages from '@/locales/event_form.json'

export default {
  props: [
    "question",
    'lang_id'
  ],
  computed: {
    msg() { return messages[this.$store.state.user.language] },
  }
}

</script>
