<template>
  <div v-if="user.authenticated" :class="required">
    <h4 class="sub-title mb-3">{{ user.language == 'ja' ? 'メールアドレス' : 'E-mail'}}</h4>
    <v-text-field v-model="answers[question.id]" :rules="rules" ></v-text-field>
  </div>
</template>

<script>

export default {
  props: [
    "question",
    "answers",
    'lang_id'
  ],
  data(){
    return {
      rules: [
        v => ( !this.question.is_required || !!v ) || "This is a required question",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
    }
  },
  computed: {
    user(){ return this.$store.state.user },
    required() {
      if (this.question.is_required) {
        return "required"
      }
    }
  },
  created() {
    console.log("あああ")
    console.log(this.$store.state.user.email)
    this.answers[this.question.id] = this.$store.state.user.email
  }
}

</script>
