<template>

    <v-card>
      <v-list>

        <v-list-item>
          <v-list-item-icon>
            <v-icon>event</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            {{format(event.start_at, "PPP")}} <br>
            {{ format(event.start_at, "p") }} ~ {{ format(event.end_at, "p" ) }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon>alarm</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <h4 class="pt-2">{{msg.apply_due}}</h4> <br>
            {{format(event.apply_due, "PPP")}} <br>
            {{ format(event.apply_due, "p") }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="!event.is_online">
          <v-list-item-icon>
            <v-icon>assistant_photo</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <h4 class="pt-2">{{msg.venue_name}}</h4> <br>
            {{lang_event.place_name}} <br>
            {{lang_event.address}}
            <!-- <iframe v-if="googleMapSrc" height="300px" :src="googleMapSrc" frameborder="0" style="border:0;"></iframe> -->
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon>info</v-icon>
          </v-list-item-icon>
          <v-list-item-content  style="white-space:pre-wrap; word-wrap:break-word;">
            <h4 class="py-2">{{ msg.description }}</h4>
            <vue-markdown class="eventdetail-markdown" :source="lang_event.discription"></vue-markdown>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="event.recieption_start_at">
          <v-list-item-icon>
            <v-icon>assignment</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <h4 class="pt-2">{{msg.reception_start_time}}</h4> <br>
            {{ format(event.recieption_start_at, "p")}}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon>people</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <h4 class="pt-2">{{msg.category}}</h4> <br>
            {{event.event_format.name}}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon>emoji_people</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <h4 class="pt-2">{{msg.purpose}}</h4> <br>
            {{event.event_purpose.name}}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon>my_location</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <h4 class="pt-2 mb-4">{{msg.target}}</h4> <br>
            <div class="body-1">
              <span v-if="event.is_shown_only_organization_universities"><strong>{{ msg.university }}:</strong> {{ target_str(event.target_universities) }}<br></span>
              <!-- <span><strong>{{ msg.department }} :</strong> {{ target_str(event.target_department_categories) }}<br></span>  -->
              <!-- <span><strong>{{ msg.country }}:</strong> {{ target_str(event.target_countries) }}<br></span>  -->
              <span><strong>{{ msg.occupation }}:</strong> {{ target_str( event.target_occupations) }}<br></span>
            </div>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon>how_to_reg</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <h4 class="pt-2">{{msg.capacity}}</h4> <br>
            {{ event.max_participant_num }}
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon>language</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <h4 class="pt-2">{{msg.spoken_language}}</h4> <br>
            {{ event.used_languages.map( l => { return l.name }).join(", ") }}
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-card>

</template>

<script type="text/javascript">
import messages from '@/locales/event_detail.json'
import VueMarkdown from 'vue-markdown'

  export default {
    components: {
      VueMarkdown,
    },
    props: [
      "lang_event",
      "event"
    ],
    data() {
      return {
        abc: null
      }
    },
    computed: {
      msg(){
        return messages[this.$store.state.user.language]
      },
      target_str(targets){
        return function(targets){
          if( targets && targets.length > 0) {
            return targets.map(ob=>{return ob.name}).join(", ")
          }else{
            return this.msg.welcome_all
          }
        }
      },
    }
  }
</script>

<style scoped>
  h4 {
    margin-top: 0px;
  }
</style>

<style>
  .eventdetail-markdown p {
    margin-bottom: 0px !important;
    line-height: 1.6
  }
  .eventdetail-markdown ol,ul {
    margin-top: -20px;
  }
  .eventdetail-markdown br {
    display: none;
  }

  .eventdetail-markdown .table {
    border-collapse: collapse
  }
  .eventdetail-markdown .table {
      width: 100%;
      margin-bottom: 1rem;
      color: #212529;
      border: 1px solid #dee2e6;
  }

  .eventdetail-markdown .table td,.eventdetail-markdown .table th {
      padding: .75rem;
      vertical-align: top;
      border-top: 1px solid #dee2e6;
      border: 1px solid #dee2e6;
  }

  .eventdetail-markdown .table thead th {
      vertical-align: bottom;
      border-bottom: 2px solid #dee2e6;
      border-bottom-width: 2px;
  }

  .eventdetail-markdown .table tbody+tbody {
      border-top: 2px solid #dee2e6
  }
  .eventdetail-markdown .table tbody tr:nth-of-type(odd) {
    background-color: #f5f5f5;
  }
  .eventdetail-markdown img {
    margin-top: 12px;
    width: 100%;
  }

</style>
