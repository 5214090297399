import Axios from 'axios';
import store from '@/store'

const axios = Axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    "X-Requested-With": "XMLHttpRequest",
  },
})

axios.interceptors.request.use((request) => {
  request.headers = {
    ...request.headers,
    "X-Requested-With": "XMLHttpRequest",
    "access-token": store.state.auth.token,
    client: store.state.auth.client,
    uid: store.state.auth.uid,
    "token-type": store.state.auth.tokenType,
    "user-locale": store.state.user.language,
  };

  return request;
})

axios.interceptors.response.use((response) => {
  return response;
})

export default axios;
