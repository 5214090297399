<template>
  <div id="app">
    <h1 class="text-center mb-4" style="white-space:pre-wrap; word-wrap:break-word;">{{msg.slogan}}</h1>

    <v-container>
      <v-row>

        <v-col cols="12">
          <h3>{{msg.event_list_reccomend}}</h3>
        </v-col>
        <!-- イベントs -->
        <events-list-view :events="sorted_university_events_collection.events"></events-list-view>

      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col cols="12">
          <h3>{{msg.near_event}}</h3>
        </v-col>

          <v-container fluid>
            <v-layout row wrap>
              <v-flex xs12 md6 class="px-3">
                <v-select :label="msg.search_language" :items="master.languages" item-text="name" item-value="id" v-model="query.used_language_id"></v-select>
              </v-flex>
              <v-flex xs12 md6 class="px-3">
                <v-select :label="msg.search_purpose" :items="master.event_purposes" item-text="name" item-value="id" v-model="query.event_purpose_id"></v-select>
              </v-flex>
              <!-- イベントs -->
              <events-list-view :events="searched_events_collection.events"></events-list-view>
            </v-layout>
          </v-container>

      </v-row>
    </v-container>

    <v-container>
      <v-row>

        <v-col cols="12">
          <h3>{{ msg.past_event_list }}</h3>
        </v-col>
        <!-- イベントs -->
        <events-list-view :events="past_famous_events_collection.events"></events-list-view>

      </v-row>
    </v-container>

  </div>
</template>

<script type="text/javascript">
import axios from "@/store/axios"
import Event from '@/models/event'
import EventsCollection from '@/models/events_collection'
import Master from '@/models/master'
import messages from '@/locales/top.json'
import EventsListView from '@/components/shared/views/events_list_view'

export default {
  components: {
    'events-list-view':EventsListView,
  },
  props: [
    "university_name"
  ],
  data: function () {
    return {
      university_events_collection: new EventsCollection(`/api/v1/events/university_all_events/${this.university_name.replace(/_/g, " ")}`),
      near_events_collection: new EventsCollection(`/api/v1/events/university_near_events/${this.university_name.replace(/_/g, " ")}`),
      near_famous_events: new EventsCollection(`/api/v1/events/near_past_events/${this.university_name.replace(/_/g, " ")}`),
      query: {
        university_id: null,
        used_language_id: null,
        event_purpose_id: null
      },
      master: new Master(),
      is_loading_search: false,
    }
  },
  computed: {
    user() { return this.$store.state.user },
    msg() { return messages[this.$store.state.user.language] },
    aligned_university_name() { return this.university_name.replace(/_/g, " ")},
    university() { return this.$store.state.header_state.university },
    searched_events_collection() { return this.near_events_collection.where(this.query).sort_by_date() },
    sorted_university_events_collection() { return this.university_events_collection.sort_by_date() },
    past_famous_events_collection() { return this.near_famous_events }
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/destroy')
    },
    aa(){
      this.near_events_collection = new EventsCollection(`/api/v1/events/university_near_events/${this.university_name.replace(/_/g, " ")}`)
    }
  },
  watch: {
    university: {
      handler(val, oldVal) {
        if (oldVal != null) {
          setTimeout(function(){
            window.location.reload()
          }, 200)
        }
      }
    }
  },
  created() {
    if ( this.university == null || this.aligned_university_name != this.university.name_en) {
      this.$store.dispatch('header_state/setUniversityByName', this.university_name)
    }
    this.$store.commit('header_state/showCurrentUniversity')
  }
}

</script>

<style scoped>
</style>
