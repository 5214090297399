// import actions from './action-types';
// import mutations from './mutation-types';
import auth from '@/store/modules/auth'
import user from '@/store/modules/user'
import header_state from '@/store/modules/header_state'
import flash from '@/store/modules/flash'
import Vuex from 'vuex';
import Vue from 'vue'

import createPersistedState from 'vuex-persistedstate'




const store = {
  state: {

  },
  modules: {
    auth: auth,
    user: user,
    header_state: header_state,
    flash: flash,
  },
  getters: {
    getUser(state) {
      return state.user
    }
  },
  plugins: [createPersistedState({
    key: 'event_site_token',
    paths: ['auth.token', 'auth.client', 'auth.uid', 'auth.tokenType', 'user.language'],
    storage: window.localStorage
  })]
};

Vue.use(Vuex)
export default new Vuex.Store(store)
