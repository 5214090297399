<template>
  <div id="app">
    <v-snackbar v-model="snackbar" :timeout="0" top color="error">
      You might already register... Please Login here <v-btn :to="login_path" class="black--text">Login</v-btn>
    </v-snackbar>

    <v-layout row wrap class="mt-0 pt-0" justify-center>
      <v-flex xs10 md6>
        <h2 class="my-3">{{msg.title}}</h2>
        <p class="mb-5">
          <strong v-if="user.language=='ja'">{{event.title}}</strong> {{msg.subdescription}} <br>
          <a v-if="!user.authenticated" @click="$router.push(login_path)" style="text-decoration: none;">{{ msg.past_register_notify }}</a>
        </p>
      </v-flex>
    </v-layout>

    <v-layout row wrap justify-center>
      <v-flex xs10 md6>
        <template v-if="!this.$store.state.user.authenticated">
          <sign-up-form ref="sign_up_form" :user_data="user_data" :input_disabled='input_disabled'></sign-up-form>
        </template>
      </v-flex>
    </v-layout>

    <v-layout row wrap justify-center>
      <v-flex xs10 md6>
        <v-form ref="apply_form">
          <v-btn v-if="other_lang && have_multilang_questions" @click="question_lang_id=other_lang.id" small class="mb-3">{{ msg.change_lang }} {{ other_lang.name }}</v-btn>
          <div v-for="question in event.apply_questions" :key="question.id" justify="center" class="mb-4">
            <aq-radio-button :question="question" :answers="answers" :lang_id="question_lang_id" v-if="question.question_type_id==1" ></aq-radio-button>
            <aq-check-box :question="question" :answers="answers" :lang_id="question_lang_id" v-if="question.question_type_id==2" ></aq-check-box>
            <aq-long-text :question="question" :answers="answers" :lang_id="question_lang_id" v-if="question.question_type_id==3" ></aq-long-text>
            <aq-short-text :question="question" :answers="answers" :lang_id="question_lang_id" v-if="question.question_type_id==4" ></aq-short-text>
            <aq-email :question="question" :answers="answers" :lang_id="question_lang_id" v-if="question.question_type_id==5" ></aq-email>
          </div>
          <!-- <v-row>
            <v-checkbox :label="msg.share_facebook" v-model="will_share"></v-checkbox>
          </v-row> -->
          <v-row class="mb-5">
            <terms-of-service></terms-of-service>
          </v-row>
          <v-row justify="center">
            <v-btn v-on:click="submit" color="success">{{msg.button_apply}}</v-btn>
          </v-row>
        </v-form>
      </v-flex>
    </v-layout>



  </div>
</template>


<script type="text/javascript">
import Event from '@/models/event'
import display_lang from '@/models/display_lang'
import axios from '@/store/axios'
import AqRadioButton from '@/components/shared/event_apply_cmpnts/radiobutton.vue'
import AqCheckBox from '@/components/shared/event_apply_cmpnts/checkbox.vue'
import AqLongText from '@/components/shared/event_apply_cmpnts/longtext.vue'
import AqShortText from '@/components/shared/event_apply_cmpnts/shorttext.vue'
import AqEmail from '@/components/shared/event_apply_cmpnts/email.vue'
import SignUpForm from '@/components/shared/sign_up_form'
import TermsOfService from '@/components/shared/terms_of_service'
import messages from '@/locales/event_apply.json'


export default {
  props: [
    "event_id"
  ],
  components: {
    'aq-radio-button': AqRadioButton,
    'aq-check-box': AqCheckBox,
    'aq-long-text': AqLongText,
    'aq-short-text': AqShortText,
    'aq-email': AqEmail,
    'sign-up-form':SignUpForm,
    'terms-of-service': TermsOfService
  },
  data() {
    return {
      event: new Event(this.event_id),
      user_data: {
        email: "",
        password: "",
        password_confirmation: "",
        confirm_success_url: "/home"
      },
      answers: {}, //{question_id: 答え, question_id: 答え}のじゅん
      input_disabled: false,
      will_share: true,
      'snackbar': false,
      question_lang_id: null,
    }
  },
  methods: {
    submit() {
      if (this.$store.state.user.authenticated) {
        // ユーザーがログイン済みだったら
        if ( this.apply_form.validate() ){
          // apply_formのバリデーションに成功したら
          console.log("応募へ")
          this.postToApply()
        }
      }
      else {
        // ユーザーが非ログインだったら
        if ( this.sign_up_form.validate() && this.apply_form.validate() ){
          // signupとapply_form両方のバリデーションに成功したら
          axios.post('/api/v1/auth/', this.user_data).then( resp => {
            // ログインに成功したら
            this.$store.dispatch('auth/create', resp.headers)
            this.postToApply()
          }).catch( resp => {
            // ログインに失敗したら
            if (resp.response.data.errors.full_messages.indexOf("Email has already been taken") >= 0){
              this.snackbar = true
            }
          })
        } else {
          // バリデーションに失敗したら
          this.apply_form.validate() //バリデーションを表示しておく
        }
      }
    },
    initialize_answers(){
      this.event.apply_questions.forEach( function(aq) {
        // TODO: そのうちmasterデータを用いて弾くようにする
        if ( aq.question_type_id != 2 ) {
          // チェックボックス以外だったら
          this.$set(this.answers, aq.id, null)
        } else {
          // チェックボックスだったら
          this.$set(this.answers, aq.id, [])
        }
      }.bind(this) )
    },
    postToApply(){
      axios.post( `/api/v1/events/${this.event_id}/applications/apply`, {application: this.postedApply} ).then( resp => {
        console.log(resp)
        // if (this.will_share){
        //   var shared_url = document.URL.replace(/\/apply/, "")
        //   window.open(`https://www.facebook.com/sharer/sharer.php?u=${shared_url}`);
        // }
        this.$router.push(`/events/${this.event_id}?just_applied=true`)
      })
    }
  },
  computed: {
    sign_up_form() { return this.$refs.sign_up_form.$refs.form },
    apply_form() { return this.$refs.apply_form },
    postedApply(){
      var ap_qu_an = {}
      Object.keys(this.answers).forEach( function(key){
        if (!Array.isArray(this.answers[key])) {
          ap_qu_an[`q_${key}`] = this.answers[key]
        } else {
          ap_qu_an[`q_${key}`] = this.answers[key].join(",")
        }
      }.bind(this))
      return {
        apply_question_answers: ap_qu_an
      }
    },
    msg(){
      return messages[this.$store.state.user.language]
    },
    user(){
      return this.$store.state.user
    },
    login_path() { return `/login?redirect_to=/events/${this.event.id}/apply` },
    other_lang() { return this.event.languages().filter( lang => { return lang.id != this.question_lang_id } )[0] },
    have_multilang_questions(){
      var multilang_questions = this.event.apply_questions.filter( aq => { return aq.question_type_id != 5 } ) // 5はメールアドレスなので多言語になっていない
      return multilang_questions.length > 0
    }
  },
  watch: {
    'event.is_fetched': function(val){
      this.question_lang_id = display_lang(this.event.language_events)
      this.initialize_answers()
      if(this.event.apply_questions.length == 0 && this.$store.state.user.authenticated){
        //質問がなくてログイン済みのとき自動でapply
        this.postToApply()
      }
    },
    'user_data.email': function(val){
      this.event.apply_questions.forEach( function(aq) {
        if (aq.question_type_id == 5) {
          this.answers[aq.id] = val
        }
      }.bind(this) )
    }
  },
  mounted(){
  }
}
</script>
