<template>
  <v-app>
    <div id="app">
      <!-- flashメッセージ用のアラート -->
      <v-snackbar :color="$store.state.flash.type" top :timeout="3000" v-model="$store.state.flash.isShown">
        {{$store.state.flash.message}}
      </v-snackbar>
      <header-component />
        <v-content>
          <!-- Provides the application the proper gutter -->
          <v-container fluid>
            <!-- If using vue-router -->
              <router-view />
          </v-container>
      </v-content>
      <footer-component />
    </div>
  </v-app>
</template>

<script>
import HeaderComponent from './components/shared/header';
import FooterComponent from './components/shared/footer';
import axios from "@/store/axios"

export default {
  components: {
    HeaderComponent,
    FooterComponent,
  },
}
</script>

<style>
.marginTop9 {
  margin-top: 90px
}
.v-content {
  min-height: 100vh;
}
.break {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.v-btn {
  text-transform: none !important;
}
</style>
