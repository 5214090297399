<template id="">
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <v-icon color="grey lighten-1" v-on="on" @click="window.open(`#/help_articles/${ha_key}`)">
        help
      </v-icon>
    </template>
    <span>Guide page</span>
  </v-tooltip>
</template>

<script>
  export default {
    props: [
      "ha_key"
    ],
  }
</script>
