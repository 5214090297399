<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="10" offset-md="1">


        <!-- 大枠 -->
        <v-container>
          <v-row class="mb-10">
            <v-col cols="12" class="text-center">
              <h2 class="pt-4 text-center">{{msg.slogan}}</h2>
              <p class="float-none mt-2">{{msg.our_aim}}</p>
              <!-- <p class="py-1">{{ msg.reason_of_the_aim }}</p> -->
            </v-col>
            <v-col cols="10">
              <v-btn to="about_us" text color="primary" outlined class="float-right">{{msg.more_about_us}}</v-btn>
            </v-col>
          </v-row>

          <!-- 機能について -->
          <v-card>
            <v-tabs background-color="grey lighten-5" active-class="primary dark" slider-color="primary" grow class="v-tabs__hidden-leftspace" icons-and-text color="white">
              <v-tab class="font-weight-bold">{{ msg.manage_events }} <v-icon>event_available</v-icon> </v-tab>
              <v-tab class="font-weight-bold">{{ msg.spread_events }} <v-icon>share</v-icon> </v-tab>
              <v-tab class="font-weight-bold">{{ msg.lock_back_events }} <v-icon>equalizer</v-icon> </v-tab>

              <v-tab-item>
                <v-container class="px-5">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-icon color="primary" x-large class="mb-2">event</v-icon>
                      <h3 class="pb-4">{{ msg.manage_1 }}</h3>
                      <p> {{ msg.manage_1_exp }} </p>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-icon color="primary" x-large class="mb-2">email</v-icon>
                      <h3 class="pb-4">{{ msg.manage_2 }}</h3>
                      <p class="break"> {{ msg.manage_2_exp }} </p>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-icon color="primary" x-large class="mb-2">mobile_friendly</v-icon>
                      <h3 class="pb-4">{{ msg.manage_3 }}</h3>
                      <p> {{ msg.manage_3_exp }} </p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item>
                <v-container class="px-5">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-icon color="primary" x-large class="mx-auto mb-3">notifications_active</v-icon>
                      <h3 class="pb-4">{{ msg.spread_1 }}</h3>
                      <p> {{ msg.spread_1_exp }} </p>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-icon color="primary" x-large class="mx-auto mb-3">send</v-icon>
                      <h3 class="pb-4">{{ msg.spread_2 }}</h3>
                      <p> {{ msg.spread_2_exp }} </p>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-icon color="primary" x-large class="mx-auto mb-3">mobile_screen_share</v-icon>
                      <h3 class="pb-4">{{ msg.spread_3 }}</h3>
                      <p> {{ msg.spread_3_exp }} </p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

              <v-tab-item>
                <v-container class="px-5">
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-icon color="primary" x-large class="mx-auto mb-3">equalizer</v-icon>
                      <h3 class="pb-4">{{ msg.look_back_1 }}</h3>
                      <p> {{ msg.look_back_1_exp }} </p>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-icon color="primary" x-large class="mx-auto mb-3">monetization_on</v-icon>
                      <h3 class="pb-4">{{ msg.look_back_2 }}</h3>
                      <p> {{ msg.look_back_2_exp }} </p>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-icon color="primary" x-large class="mx-auto mb-3">pie_chart</v-icon>
                      <h3 class="pb-4">{{ msg.look_back_3 }}</h3>
                      <p> {{ msg.look_back_3_exp }} </p>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>

            </v-tabs>
          </v-card>

          <!-- Get started について -->
          <div class="mt-12 mb-7">
            <h2 class="mb-3">{{ msg.how_to_get }}</h2>
            <v-row>
              <v-col cols="12" md="3">
                <v-card outlined height="100%">
                  <v-card-text class="text-center pt-7">
                    <v-icon class="image-icon" color="primary">account_circle</v-icon>
                  </v-card-text>
                  <v-card-title class="pt-0">{{msg.how_to_get_1}}</v-card-title>
                </v-card>
              </v-col>
              <v-col cols="12" md="3">
                <v-card outlined height="100%">
                  <v-card-text class="text-center pt-7">
                    <v-icon class="image-icon" color="primary">supervised_user_circle</v-icon>
                  </v-card-text>
                  <v-card-title class="pt-0">{{msg.how_to_get_2}}</v-card-title>
                </v-card>
              </v-col>
              <v-col cols="12" md="3">
                <v-card outlined height="100%">
                  <v-card-text class="text-center pt-7">
                    <v-icon class="image-icon" color="primary">person_add</v-icon>
                  </v-card-text>
                  <v-card-title class="pt-0">{{msg.how_to_get_3}}</v-card-title>
                </v-card>
              </v-col>
              <v-col cols="12" md="3">
                <v-card outlined height="100%">
                  <v-card-text class="text-center pt-7">
                    <v-icon class="image-icon" color="primary">event_note</v-icon>
                  </v-card-text>
                  <v-card-title class="pt-0">{{msg.how_to_get_4}}</v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </div>

          <!-- ボタン -->
          <v-row>
            <v-col class="text-center">
              <v-btn to="/signup" color="success" x-large class="font-weight-bold d-none d-md-inline-flex">{{ msg.to_organize_events }}</v-btn>
              <v-btn to="/signup" color="success" large class="font-weight-bold d-md-none">{{ msg.to_organize_events }}</v-btn>
              <p class="mt-2">{{ msg.caution_for_organizing }}</p>
            </v-col>
          </v-row>

          <!-- <h3 class="my-7">{{ msg.flow_to_organize_event }}</h3>
          <p class="my-2">{{ msg.flow_to_organize_event_1 }}</p>
          <p class="my-2">{{ msg.flow_to_organize_event_2 }}</p>
          <p class="mt-2 mb-10">{{ msg.flow_to_organize_event_3 }}</p>

          <v-col cols="12" class="text-center mt-10 mb-2">
            <v-btn to="/signup" color="success">{{ msg.to_organize_events }}</v-btn>
          </v-col>
          <v-col cols="10" class="text-right">
            <p class="red--text">{{ msg.caution_for_organizing }}</p>
          </v-col>

          <h2 class="my-10">{{ msg.faq }}</h2> -->
        </v-container>

      </div>


    </v-col>
  </v-row>
</v-container>

</template>
<script type="text/javascript">
import messages from '@/locales/guide_for_organizers.json'


export default{
  data(){
    return{
      tab:null,
    }
  },
  computed:{
    msg() { return messages[this.$store.state.user.language] },
  },
}
</script>

<style scoped>
.image-icon {
  font-size: 100px !important;
}
</style>

<style>
.v-card .v-card__title {
  word-break: break-word;
}
.v-tabs__hidden-leftspace .v-tabs-bar .v-slide-group__prev {
  display: none !important;
}
</style>
