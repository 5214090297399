export default {
  namespaced: true,
  state: {
    client: '',
    uid: '',
    token: '',
    tokenType: '',
  },
  mutations: {
    create (state, headers) {
      state.client = headers["client"]
      state.token = headers["access-token"]
      state.uid = headers["uid"]
      state.tokenType = headers["token-type"]
    },
    destroy (state) {
      state.client = ''
      state.uid = ''
      state.token = ''
      state.tokenType = ''
    },
  },
  actions: {
    create ({ commit, dispatch }, headers) {
      commit('create', headers)
      dispatch('user/update', null, { root: true })
    },
    destroy ({ commit, dispatch  }) {
      commit('destroy')
      dispatch('user/destroy', null, { root: true })
    },
  }
}
