import axios from '@/store/axios'
import University from '@/models/university'

const defaultState = {
  university: null,
  type: null, // typeはnone, university, selectionの三つ
}


export default {
  namespaced: true,
  state: JSON.parse(JSON.stringify(defaultState)),
  mutations: {
    setUniversity (state, university) {
      if (university.name_en == null) { return }
      state.university = university
    },
    unsetUniveristy (state) {
      state.university = null
    },
    hideFromHeader (state) {
      state.type = "none"
    },
    showCurrentUniversity (state) {
      state.type = "university"
    },
    showSelection (state) {
      state.type = "selection"
    },
  },
  actions: {
    setUniversityByName(context, university_name){
      var university = new University(university_name)
      context.commit('setUniversity', university)
    }
  }
}



// headerのステートとして、
// 1. universityのTopページにいるとき → university
// 2. 本物のTopページにいるとき → selection
// 3. 他のイベント詳細ページなどにいるとき → none
// がある。
