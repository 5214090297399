import axios from '@/store/axios'
import University from '@/models/university'

const defaultState = {
  id: null,
  authenticated: false,
  first_name: '',
  last_name: '',
  email: '',
  profile_image: '',
  language: 'en',
  is_uniteam: false,
  university: null
  // country: {
  //   id: null,
  //   name_ja: '',
  //   name_en: ''
  // },
  // occupation: {
  //   id: null,
  //   name_ja: '',
  //   name_en: ''
  // }
}

export default {
  namespaced: true,
  state: JSON.parse(JSON.stringify(defaultState)),
  mutations: {
    update (state, data) {
      state.id = data.id
      state.first_name = data.first_name
      state.last_name = data.last_name
      state.email = data.email
      state.profile_image = data.profile_image
      state.is_uniteam = data.is_uniteam
      state.authenticated = true
      // axios.get("/api/v1/users/own").then( resp => {
      //   // console.log(resp.data)
      // } ).catch( resp => {
      //   console.log("some error occur in user.js")
      //   this.state.authenticated = false
      // } )
    },
    destroy (state) {
      Object.assign(state, defaultState)
      console.log(defaultState)
      console.log(state)
    },
    updateLanguage(state, lang) {
      state.language = lang
    },
    markUnauthenticated(state) {
      state.authenticated = false
    },
    setUniversity(state, university) {
      state.university = university
    }
  },
  actions: {
    update ({ commit }) {
      axios.get("/api/v1/users/own").then(resp=>{
        commit('update', resp.data.data)
      })
    },
    destroy ({ commit }) {
      commit('destroy')
    },
    setUniversity({commit}){
      axios.get('/api/v1/masters/own_university').then(resp => {
        var university = new University(resp.data)
        commit("setUniversity", university)
      } )
    }
  },
  getter: {
    full_name() {
      return first_name + ' ' + last_name
    }
  }
}
