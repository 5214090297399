<template>
  <v-form ref="form" lazy-validation>
    <v-text-field v-model="organization.language_organization(organization_lang_id).name" label="Name" class="required" :rules="nameRules"></v-text-field>
    <v-text-field v-model="organization.email" label="E-mail" class="required" :rules="emailRules"></v-text-field>
    <v-text-field v-model="organization.phone_num" label="Phone Number"></v-text-field>
    <v-textarea v-model="organization.language_organization(organization_lang_id).discription" label="Description"></v-textarea>
    <v-select label="Prefectures" :items="master.prefectures" item-text="name" item-value="id" multiple v-model="organization.prefecture_ids"></v-select>
    <v-select label="Affiliated Universities" :items="target_university_options" item-text="name" item-value="id" multiple v-model="organization.university_ids" no-data-text="You can choose universities only in main prefectures">
    </v-select>
    <v-text-field v-model="organization.link_instagram" label="Instagram Link"></v-text-field>
    <v-text-field v-model="organization.link_wechat" label="WeChat Link"></v-text-field>
    <v-text-field v-model="organization.link_facebook" label="Facebook Link"></v-text-field>
    <v-text-field v-model="organization.link_line" label="LINE Link"></v-text-field>
    <v-text-field v-model="organization.link_x" label="X Link"></v-text-field>
    <v-text-field v-model="organization.link_official_site" label="Official Website Link"></v-text-field>
  </v-form>
</template>


<script>

export default {
  props:[
    'organization_lang_id',
    'organization',
    'master'
  ],
  data(){
    return {
      nameRules: [ v => ( !!v ) || "Name is required." ],
      emailRules: [ v => ( !!v ) || "Email can't be blank", v => /.+@.+\..+/.test(v) || "E-mail must be valid" ],
    }
  },
  computed: {
    target_university_options() {
      return this.master.universities.filter(univ => {
        return this.organization.prefecture_ids.includes(univ.prefecture_id) || this.organization.university_ids.includes(univ.id)
      })
    }
  }
}
</script>
