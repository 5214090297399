<template>
  <v-container class="">
    <v-snackbar v-model="snackbar" :timeout="1500" top color="error">
      {{msg.button_choose_language}}
    </v-snackbar>
    <h2 class="mb-5">{{msg.organization_created}}</h2>
    <h4>{{msg.ask_add_other_language}}</h4>
    <v-form class="mb-5">
      <v-radio-group v-model="selected_lang_id">
        <v-radio v-for="lang in master.commonly_used_lanugages()" :value="lang.id" :label="lang.name" v-if="organization.language_ids().indexOf(lang.id) < 0"></v-radio>
      </v-radio-group>
      <div class="">
        <h4>{{msg.other}}</h4>
        <v-select :label="msg.button_choose_language" v-model="selected_lang_id" :items="master.not_commonly_used_lanugages()" item-value="id" item-text="name"></v-select>
      </div>
    </v-form>

    <v-btn @click="submit" color="success">{{msg.button_language}}</v-btn>
    <v-btn @click="$router.push(`/manage/organizations/${organization_id}`)">{{msg.button_later}}</v-btn>
  </v-container>
</template>

<script>
import axios from "@/store/axios"
import Organization from "@/models/organization"
import Master from "@/models/master"
import messages from '@/locales/select_lang.json'

export default {
  props: [
    'organization_id',
  ],
  data: function() {
    return {
      organization: new Organization(this.organization_id),
      selected_lang_id: null,
      snackbar: false,
      master: new Master(),
    }
  },
  methods: {
    submit() {
      if (this.selected_lang_id) {
        axios.post(`/api/v1/language_organizations/create`, this.posted()).then(resp => {
          this.$router.push(`/manage/organizations/${this.organization_id}/create_lang`)
        })
      }else {
        this.snackbar = true
      }
    },
    posted (){
      return {organization_id: this.organization_id, language_id: this.selected_lang_id}
    }
  },
  computed: {
    msg() { return messages[this.$store.state.user.language] },
  }

}
</script>
