/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Vue from "vue";
import Vuex from "vuex";
import VueRouter from "vue-router";
import DatetimePicker from "vuetify-datetime-picker";
import MySnackbar from "@/components/shared/views/my_snackbar";
import HelpArticleIcon from "@/components/shared/views/help_article_icon";
// import 'vuetify-datetime-picker/src/stylus/DatetimePicker.styl'
import store from "@/store";
import router from "./routes";
import App from "./app";
import vuetify from "@/plugins/vuetify";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import mixin from "./mixin/mixin";
import Vuetify from "vuetify";

// import './assets/css/application';

Vue.use(Vuex);
Vue.use(VueRouter);
Vue.use(Vuetify);
Vue.use(DatetimePicker);
Vue.component("ha-icon", HelpArticleIcon);
Vue.component("MySnackbar", MySnackbar);
Vue.mixin(mixin);

document.addEventListener("DOMContentLoaded", () => {
  new Vue({
    el: "#app",
    vuetify,
    store,
    router,
    render: (h) => h(App),
  });
});

Date.prototype.yyyymmdd = function () {
  var mm = this.getMonth() + 1; // getMonth() is zero-based
  var dd = this.getDate();
  var lang = store.state.user.language;

  if (lang == "ja") {
    return [
      this.getFullYear() + "年",
      (mm > 9 ? "" : "0") + mm + "月",
      (dd > 9 ? "" : "0") + dd + "日",
    ].join("");
  }
  if (lang == "en") {
    return this.getMonthStr() + ". " + dd.nth() + ", " + this.getFullYear();
  }
};
Date.prototype.hhMM = function () {
  var hh = this.getHours(); // getMonth() is zero-based
  var mm = this.getMinutes();

  return [(hh > 9 ? "" : "0") + hh, ":", (mm > 9 ? "" : "0") + mm].join("");
};
Date.prototype.getDateStr = function () {
  var lang = store.state.user.language;
  if (lang == "ja") {
    return this.getDate() + "日";
  }
  if (lang == "en") {
    return this.getDate();
  }
};
Date.prototype.getMonthStr = function () {
  var lang = store.state.user.language;
  if (lang == "ja") {
    return this.getMonth() + 1 + "月";
  }
  if (lang == "en") {
    return [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ][this.getMonth()];
  }
};

Date.prototype.isPast = function () {
  if (new Date().getTime() - this.getTime() > 1000 * 60 * 60 * 24) {
    //1000*60*60*24msec = 1day
    return true;
  } else {
    return false;
  }
};

Number.prototype.nth = function () {
  if (this % 1) return this; //整数かの判断ぽい
  var s = this % 100;
  if (s > 3 && s < 21) return this + "th";
  switch (s % 10) {
    case 1:
      return this + "st";
    case 2:
      return this + "nd";
    case 3:
      return this + "rd";
    default:
      return this + "th";
  }
};
