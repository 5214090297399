<template>
  <div id="app">
    <v-layout wrap row justify-center>
      <v-flex xs9 md6>
        <h2 class="my-5 text-left">{{msg.successfully_logout_header}}</h2>
        <p>
          {{msg.thanks_message}}
        </p>
        <h4 class="mb-2">{{msg.return_message}}</h4>
        <p>
          <router-link to="/" class="mr-3">{{msg.return_top}}</router-link>
          <router-link to="/login">{{msg.return_login}}</router-link>
        </p>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import messages from '@/locales/logout.json'

export default {
  computed: {
    msg() { return messages[this.$store.state.user.language] },
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/destroy')
    },
  },
  created(){
    this.logout()
  }
}
</script>
