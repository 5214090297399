<template>
  <div id="app">
    <v-snackbar v-model="snackbar" :timeout="0" top color="error">
      Please start from the biginning.
    </v-snackbar>
    <v-layout wrap row justify-center>
      <v-flex xs9 md6>
        Change Password
      </v-flex>
    </v-layout>

    <v-form ref="form" lazy-validation>
      <v-row justify="center">
        <v-col cols="6">
          <v-text-field v-model="user_data.password" type="password" class="required" :rules="passwordRules" label='New Password' required ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="6">
          <v-text-field v-model="user_data.password_confirmation" type="password" class="required" :rules="passwordConfirmRules" label='New Password Confirmation' required ></v-text-field>
        </v-col>
      </v-row>
    </v-form>

    <v-layout wrap row justify-center>
      <v-flex xs9 md6>
        <v-btn block color="success" class="mr-4" @click="submit">{{msg.submit}}</v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "@/store/axios"
import SignUpForm from '@/components/shared/sign_up_form'
import TermsOfService from '@/components/shared/terms_of_service'
import messages from '@/locales/sign_up_form.json'
import top_messages from '@/locales/top.json'

export default {
  data: function () {
    return {
      user_data: {
        password: "",
        password_confirmation: ""
      },
      headers: {
        uid: this.getParam('uid'),
        "access-token": this.getParam("access-token"),
        client: this.getParam('client')
      },
      passwordRules: [
        v => ( !!v ) || "Password can't be blank",
        v => (!!v && v.length >= 6) || "Password must be more than 6 characters" ],
      passwordConfirmRules: [
        v => !!v || "Password Confirm can't be blank",
        v => v === this.user_data.password || 'Password confirm is equal to password'],
      'snackbar': false,
    }
  },
  computed: {
    msg() { return Object.assign(messages[this.$store.state.user.language], top_messages[this.$store.state.user.language]) },
  },
  methods: {
    submit: function () {
      this.input_disabled = true
      axios.put('/api/v1/auth/password', this.user_data).then( resp => {
        window.location.href = "/" //できれば将来的には元いたページにリダイレクトさせてあげたい
      }
      ).catch( resp => {
        this.input_disabled = false
        this.snackbar = true
        console.log(resp);
      } )
    },
    getParam(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    }
  },
  mounted() {
    this.$store.dispatch('auth/create', this.headers)
  }
}
</script>

<style scoped>
/* p {
  font-size: 2em;
  text-align: center;
} */
</style>
