<template>
  <v-container id="app">
    <h3>{{msg.create_organization}}</h3>
    <p class="mb-5">{{msg.subdescription}}</p>
    <v-select v-model="organization.language_organizations[0].language_id" :items="master.languages" item-text="name" item-value="id" :label="msg.register_language" class="required"></v-select>
    <organization-form ref="org_form" :organization="organization" :organization_lang_id="organization.language_organizations[0].language_id" :master="master"></organization-form>
    <v-btn color="success" class="mr-4" @click="submit">{{msg.button_create_group}}</v-btn>
    <!-- <button @click="submit">登録</button> -->
  </v-container>
</template>

<script type="text/javascript">
import axios from '@/store/axios'
import Organization from '@/models/organization'
import OrganizationForm from './organization_form'
import Master from '@/models/master'
import messages from '@/locales/create_organization.json'

export default {
  data: function () {
    return {
      organization: new Organization(),
      master: new Master()
    }
  },
  components: {
    'organization-form': OrganizationForm
  },
  computed: {
    user() { return this.$store.state.user },
    form() { return this.$refs.org_form.$refs.form },
    msg() { return messages[this.$store.state.user.language] },
  },
  created () {
    // this.$store.dispatch('auth/destroy')
    this.$store.dispatch('user/update')
  },
  methods: {
    submit(){
      if (this.form.validate()) {
        axios.post( '/api/v1/organizations/create', { organization: this.organization.toPostedOrganization() } ).then( resp => {
          this.$router.push(`/manage/organizations/${resp.data.data.id}/select_lang`)
        } )
      }
    },
  }
}

</script>
