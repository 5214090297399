import axios from '@/store/axios'
import University from '@/models/university'

const defaultState = {
  message: "",
  type: "info", // typeはsuccess, info, warning, error の4つ
  isShown: false,
}


export default {
  namespaced: true,
  state: JSON.parse(JSON.stringify(defaultState)),
  mutations: {
    set (state, arg) {
      state.type = arg.type
      state.message = arg.message
      state.isShown = false
      setTimeout(function(){
        state.isShown = true
      }, 100)
    }
  },
}
