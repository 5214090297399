<template id="">
  <v-container>
    <h2>Help Articles</h2>
    <v-card class="my-4" v-for="help_article in help_articles"  :key="help_article.id" @click="$router.push(`/uniteamanage/help_articles/${help_article.key}/edit`)">
      <v-card-title>{{ help_article.title }}</v-card-title>
      <v-card-text>key: {{help_article.key}}</v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import HelpArticle from "@/models/help_article"
import axios from "@/store/axios"

  export default {
    data() {
      return {
        help_articles: []
      }
    },
    created(){
      axios.get("/api/v1/help_articles").then(resp=>{
        resp.data.forEach(hao=>{
          this.help_articles.push(new HelpArticle(hao))
        })
      })
    }
  }
</script>
