<template>
  <modal @close="$emit('close')">
    <h3 slot="header">{{msg.event_edit}}</h3>
    <div slot="body">
      <v-tabs grow>

        <v-tab class="ml-0">{{event.main_language_event().language.name}}</v-tab>
        <v-tab>{{event.second_language_event() ? event.second_language_event().language.name : no_second_lang }}</v-tab>

        <v-tab-item>
          <event-form ref="main_form" :event="event" :event_lang_id="event.main_language_event().language_id"></event-form>
        </v-tab-item>
        <v-tab-item>
          <event-form ref="second_form" :event="event" v-if="event.second_language_event()" :event_lang_id="event.second_language_event().language_id"></event-form>
          <div v-if="!event.second_language_event()" class="text-center my-5">
            <h3 class="my-5">{{no_second_msg.no_second_lang}}</h3>
            <p>
              {{no_second_msg.to_register_second_lang}}
            </p>
          </div>
        </v-tab-item>
      </v-tabs>
      <v-btn color="success" @click="submit">{{msg.save}}</v-btn>

    </div>
  </modal>
</template>

<script>
import Modal from '@/components/shared/views/modal';
import EventForm from '@/components/shared/manage_organization_components/event_form.vue'
import axios from '@/store/axios'
import messages from '@/locales/event_form.json'
import no_second_lang_messages from '@/locales/no_second_lang.json'

export default {
  components: {
    Modal,
    EventForm,
  },
  props: [
    "event"
  ],
  computed: {
    main_form() {return this.$refs.main_form.$refs.form},
    second_form() {return this.$refs.second_form ? this.$refs.second_form.$refs.form : undefined},
    no_second_lang() { return this.$store.state.user.language == "ja" ? "未設定" : "Not Set" },
    msg() { return messages[this.$store.state.user.language] },
    no_second_msg() { return no_second_lang_messages[this.$store.state.user.language] },
  },
  methods: {
    submit() {
      if ( this.main_form.validate() && ( !this.second_form || this.second_form.validate()) ){
        axios.put(`/api/v1/events/${this.event.id}/update`, {event: this.event.toPostedEvent()}).then( resp => {
          this.event.update()
          this.flash("Event was successfully updated", "success")
          this.$emit('close')
        }).catch( err => {
          var key = Object.keys(err.response.data)[0]
          this.flash(key+" "+err.response.data[key][0], "error")
        } )
      }
    }
  }
}
</script>
