<template>
  <v-container fluid>

    <my-snackbar ref="snackbar"></my-snackbar>

    <v-layout row wrap justify-center>
      <v-flex xs12 md8 v-if="is_following">
        <h3>{{msg.unfollow_header1}}{{ organization.name }}{{msg.unfollow_header2}} </h3>
        <p>{{msg.unfollow_description1}}{{organization.name}}{{msg.unfollow_description2}}</p>
        <v-btn @click="unfollow" class="mt-5"> Unfollow </v-btn>
      </v-flex>
      <v-flex xs12 md8 v-if="!is_following">
        <h3>{{msg.after_unfollow_header1}}{{ organization.name }}{{msg.after_unfollow_header2}}</h3>
        <p>
          {{msg.after_unfollow_description1}}{{ organization.name }}{{msg.after_unfollow_description2}}<br>
          {{msg.after_unfollow_description3}}{{organization.name}}{{msg.after_unfollow_description4}}
        </p>
        <v-btn @click="follow" class="mt-5"> Follow </v-btn>
      </v-flex>
    </v-layout>

  </v-container>

</template>


<script type="text/javascript">
//import messages from '@/locales/event_apply.json'
import axios from "@/store/axios"
import Organization from '@/models/organization'
import messages from '@/locales/unfollow.json'

export default {
  props: [
    "organization_id"
  ],
  data() {
    return {
      organization: new Organization(this.organization_id),
      is_following: null,
    }
  },
  computed: {
    login_path() { return `/login?redirect_to=/organizations/${this.organization_id}/unfollow` },
    msg(){
      return messages[this.$store.state.user.language]
    },
    user(){
      return this.$store.state.user
    },
  },
  methods: {
    unfollow(){
      console.log("submit")
      axios.put(`/api/v1/organizations/${this.organization_id}/unfollow`).then(resp =>{
        this.$refs.snackbar.showSuccess("Unfollowed")
        this.is_following = !resp.data.is_unfollow
      }).catch(resp =>{
        this.$refs.snackbar.showError("Error Occured. Please Retry.!")
      })
    },
    follow(){
      axios.put(`/api/v1/organizations/${this.organization_id}/follow`).then(resp =>{
        this.$refs.snackbar.showSuccess("Followed!")
        this.is_following = !resp.data.is_unfollow
      }).catch(resp =>{
        this.$refs.snackbar.showError("Error Occured. Please Retry.!")
      })
    },
    setIsFollowing(){
      axios.get(`/api/v1/organizations/${this.organization_id}/own_follow_state`).then(resp=>{
        console.log(resp);
        this.is_following = !resp.data.is_unfollow
      })
    }
  },
  mounted() {
    if (!this.user.authenticated) { this.$router.push(this.login_path()) }
    this.setIsFollowing()
  }
}
</script>
