import axios from '@/store/axios'

export default class RemindEventMail {

  constructor(arg) {
    this.init()
    // if ( typeof arg == "string" ) {
    //   this.id = arg
    //   this.fetch()
    // }
    if ( typeof arg == "object" ) {
      Object.assign(this, arg)
      this.is_fetched = true
    }
  }

  // fetch() {
  //   console.log("fetching")
  //   axios.get(`/api/v1/events/${this.id}`).then( resp => {
  //     Object.assign(this, resp.data)
  //     this.is_fetched = true
  //   })
  // }

  init() {
    this.id = null
    this.event_id = null
    this.send_at = null
    this.title = null
    this.custom_body = null
    this.sent_at = null
    // 状態管理用
    this.is_fetched = false
  }

  send_at_date(){
    return new Date(this.send_at)
  }

  // update(){
  //   this.fetch()
  // }


  toPostedRemindEventMail() {
    var postedRemindEventMail = {}
    postedRemindEventMail.id = this.id
    postedRemindEventMail.send_at = this.send_at
    postedRemindEventMail.title = this.title
    postedRemindEventMail.custom_body = this.custom_body
    return postedRemindEventMail
  }

}
