import format from 'date-fns/format';
import { ja, enUS } from 'date-fns/locale'

export default {
  computed: {
    user() { return this.$store.state.user },
    format() {
      return function(date, formatStr) {
        // nilガード
        if ( date == null ) { return "" }
        // 言語設定
        var locale
        if ( this.user.language == "ja" ) { locale = ja }
        if ( this.user.language == "en" ) { locale = enUS }
        // https://date-fns.org/v2.9.0/docs/format を参考にして使うと良い
        return format(date, formatStr, {locale: locale}) // 結果を返す
      }
    },
    window(){ return window }
  },
  methods: {
    segueToCurrentUniversityTop: function() {
      var university = this.$store.state.header_state.university
      if (this.$store.state.header_state.university && this.$store.state.header_state.university.name_en) {
        this.$router.push(`/u/${university.snake_name()}`)
      }else{
        this.$router.push("/")
      }
    },
    flash(message, type) {
      this.$store.commit('flash/set', {message: message, type: type})
    },
    truncate(str, length) {
      if (str.length <= length) {
        return str
      } else {
        return str.slice(0, length) + "..."
      }
    }
  }
}
