import store from '@/store'

export default function(lang_objects) {
  var user_lang = language_symbols.indexOf(store.state.user.language)
  var candidates = lang_objects.map(ob => { return ob.language_id }) //候補

  // 優先順位1: ユーザーの言語と同じ言語がある時、その言語を返す
  if ( candidates.indexOf(user_lang) >= 0 ) { return user_lang }
  // 優先順位2: ユーザーの言語と同じ言語がない時で、ユーザー言語が英語より日本語が得意な言語で、日本語が候補にある時
  if ( japanese_supers.indexOf(user_lang) >= 0 && candidates.indexOf(1) >= 0 ) { return 1 }
  // 優先順位3: ユーザーの言語と同じ言語がない時で、ユーザー言語が英語の方が得意な言語で、英語が候補にある時
  if ( japanese_supers.indexOf(user_lang) < 0 && candidates.indexOf(2) > 0 ) { return 2 }
  // 優先順位4: とりあえす英語がある時
  if ( candidates.indexOf(2) > 0 ) { return 2 }
  // 優先順位5: とりあえず日本語がある時
  if ( candidates.indexOf(1) > 0 ) { return 1 }
  // 最低: 登録した人のメイン言語を返す
  return candidates[0]
}

var japanese_supers = [1] // 日本語の方が優位な言語id (今のところ日本語だけ)
var language_symbols = ["0番の埋め合わせです", "ja", "en"]
