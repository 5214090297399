<template>
  <div id="app">
    <h3>{{msg.followers}}</h3>
    <v-data-table :headers="headers" :items="followers" calculate-widths hide-default-footer :items-per-page="500" class="elevation-1 my-5">
    </v-data-table>
  </div>
</template>

<script type="text/javascript">
import axios from '@/store/axios'
import OrganizationForm from '@/components/shared/organization_form'
import NoSecondLang from '@/components/shared/manage_organization_components/no_second_lang'
import column_messages from '@/locales/sign_up_form.json'
import messages from '@/locales/followers.json'

export default {
  props: [
    "organization",
  ],
  data: function () {
    return {
      'followers': [],
    }
  },
  computed: {
    user() { return this.$store.state.user },
    headers(){
      return [
        {text: this.col_msg.first_name, value: "first_name"},
        {text: this.col_msg.last_name, value: "last_name"},
      ]
    },
    col_msg() { return column_messages[this.$store.state.user.language] },
    msg() { return messages[this.$store.state.user.language] },
  },
  methods: {
    getFollowers(){
      axios.get(`/api/v1/organizations/${this.organization.id}/followers`).then(resp => {
        this.followers = resp.data
      })
    }
  },
  mounted() {
    this.getFollowers()
  }
}

</script>
