<template>
  <v-container>
    <v-btn absolute right class="mr-5" @click="handleDownload">Download CSV</v-btn>
    <h3>{{msg.participant_list}}</h3>
    <p><v-icon size="20">mdi-account</v-icon> {{ applyingUsers.length }}</p>
    <div style="overflow-x: scroll">
      <div :style="{width: table_width}">
        <v-data-table :headers="cal_headers" :items="applyingUsers" calculate-widths :no-data-text="msg.no_participants" hide-default-footer :items-per-page="500" class="elevation-1 my-5" id="applying_users_table">
          <template v-for="aq in event.apply_questions" :slot="`item.q_${aq.id}`" slot-scope="item" >
            {{ application(item.item.id).apply_question_answers ? application(item.item.id).apply_question_answers[item.header.value] : "" }}
          </template>
          <template v-slot:[`item.apply_at`]="{ item }">
            <div>{{ formatStringDate(item.apply_at, "PPP") }}</div>
          </template>
        </v-data-table>
      </div>
    </div>

    <h3>{{msg.cancell_list}}</h3>
    <p><v-icon size="20">mdi-account</v-icon> {{ cancelledUsers.length }}</p>
    <div style="overflow-x: scroll">
      <div :style="{width: table_width}">
        <v-data-table :headers="cal_headers" :items="cancelledUsers" calculate-widths :no-data-text="msg.no_cancells" hide-default-footer :items-per-page="500" class="elevation-1 my-5" id="cancelled_users_table">
          <template v-for="aq in event.apply_questions" :slot="`item.q_${aq.id}`" slot-scope="item" >
            {{ application(item.item.id).apply_question_answers ? application(item.item.id).apply_question_answers[item.header.value] : "" }}
          </template>
         <template v-slot:[`item.apply_at`]="{ item }">
            <div>{{ formatStringDate(item.apply_at, "PPP") }}</div>
          </template>
        </v-data-table>
      </div>
    </div>


  </v-container>
</template>

<script type="text/javascript">
import messages from '@/locales/list.json'
import column_messages from '@/locales/sign_up_form.json'
import { parseISO } from 'date-fns'

export default {
  props: [
    "organization_id",
    "event_id",
    "event"
  ],
  computed: {
    headers(){
      return [
        {text: this.col_msg.first_name, value: "first_name"},
        {text: this.col_msg.last_name, value: "last_name"},
        {text: this.col_msg.country, value: "country.name"},
        {text: this.col_msg.occupation, value: "occupation.name"},
        {text: this.col_msg.university, value: "university.name"},
        {text: this.col_msg.department, value: "department_category.name"},
        {text: this.col_msg.application_date, value: 'apply_at'},
      ]
    },
    user() { return this.$store.state.user },
    applyUsersWithApplyAtAndIsCancelled() {
      return this.event.apply_users.map(user => {
        const application = this.event.applications.find(application => application.user_id === user.id)

        return {
          ...user,
          apply_at: application.apply_at,
          is_cancelled: application.is_cancelled
        }
      })
    },
    applyingUsers(){
      return this.applyUsersWithApplyAtAndIsCancelled.filter(user => !user.is_cancelled)
    },
    cancelledUsers(){
      return this.applyUsersWithApplyAtAndIsCancelled.filter(user => user.is_cancelled)
    },
    cal_headers() {
      var headers = Object.assign([], this.headers)
      this.event.apply_questions.forEach(aq => {
        if ( aq.question_type_id == 5 ) { headers.push({text: "Mail", value: `q_${aq.id}`}) }
        else { headers.push({text: aq.language_apply_questions[0].title, value: `q_${aq.id}`}) }
        console.log({text: aq.language_apply_questions[0].title, value: `q_${aq.id}`})
      })
      return headers
    },
    table_width() {
      var count = 0
      this.cal_headers.forEach(header=>{
        count += header.text.length
      })
      var ratio = 25
      if (this.$store.state.user.language == "ja") { ratio = 120 }
      return count*ratio + "px"
    },
    application(user_id){
      return function(user_id){
        return this.event.applications.filter( aq => {
          return aq.user_id == user_id
        })[0]
      }
    },
    msg() { return messages[this.$store.state.user.language] },
    col_msg() { return column_messages[this.$store.state.user.language] },
  },
  methods: {
    handleDownload() {
      var bom = new Uint8Array([0xEF, 0xBB, 0xBF]);
      var content = this.applications_csv_content();
      var blob = new Blob([ bom, content ], { "type" : "text/csv" });

      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, "test.csv");
        // msSaveOrOpenBlobの場合はファイルを保存せずに開ける
        window.navigator.msSaveOrOpenBlob(blob, "test.csv");
      } else {
        var a = document.createElement("a")
        a.href = window.URL.createObjectURL(blob);
        a.click()
      }
    },
    applications_csv_content() {
      const apply_table = document.querySelector('#applying_users_table table');//id=table1という要素を取得
      const cancel_table = document.querySelector('#cancelled_users_table table');//id=table1という要素を取得
      return this.make_csv_from_table(apply_table, "Applying") + this.make_csv_from_table(cancel_table, "Cancelled")
    },
    make_csv_from_table(table, state){
      var data_csv = ""
      for(var i = 0;  i < table.rows.length; i++){
        if (state == "Cancelled" && i == 0) { continue } // キャンセルの場合はヘッダーをexportしない
        if ( table.rows[i].classList.contains("v-data-table__empty-wrapper") ) { break } // 何もデータがない時はexportしない
        // 行の通常処理
        for(var j = 0; j < table.rows[i].cells.length; j++){
          data_csv += table.rows[i].cells[j].innerText;//HTML中の表のセル値をdata_csvに格納
          data_csv += ",";//セル値の区切り文字として,を追加
        }
        // 行の最終処理
        if (i == 0){ data_csv += `STATUS\n`; } //一番上だったらSTATUS文字と改行コードを追加
        else { data_csv += `${state}\n`; } //それ以外だったらSTATUS自体と改行コードを追加
      }
      return data_csv
    },
    formatStringDate(str, format){
      return this.format(parseISO(str), format)
    }
  },
}

</script>
