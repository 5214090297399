<template>
  <div id="app">

    <v-snackbar v-model="snackbar" :timeout="0" top color="error">
      Your email or password is incorrect. Please send email to harmonyfor.info@gmail.com.
    </v-snackbar>

    <v-container class="marginTop9">
      <v-row justify="center">
        <v-col cols="6">
          <h2>Login</h2>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="6">
          <v-text-field v-model="user_data.email" label="Email"></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="6">
          <v-text-field v-model="user_data.password" label="Password" type="password"></v-text-field>
          <a @click="$router.push('/login/reset')">forgot password?</a>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="3">
          <v-btn color="success" block @click="submit">login</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "@/store/axios"
import University from "@/models/university"

export default {
  data: function () {
    return {
      user_data: {
        email: "",
        password: "",
        confirm_success_url: "/home"
      },
      'snackbar': false,
      input_disabled: false
    }
  },
  methods: {
    submit: function () {
      this.input_disabled = true
      axios.post('/api/v1/auth/sign_in', this.user_data).then( resp => {
        this.$store.dispatch('auth/create', resp.headers)
        if (this.$route.query.redirect_to) {
          this.$router.push(this.$route.query.redirect_to);
        } else {
          axios.get('/api/v1/masters/own_university').then(resp => {
            var university = new University(resp.data)
            this.$store.commit("header_state/setUniversity", university)
            this.segueToCurrentUniversityTop()
          })
        }
      }).catch( resp => {
        this.input_disabled = false
        this.snackbar = true
        console.log(resp);
      } )
    }
  },
}
</script>

<style scoped>
p {
  font-size: 2em;
  text-align: center;
}
</style>
