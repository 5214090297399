<template>
  <div id="app">
    <h2>{{msg.no_second_lang}}</h2>
    <div class="mt-5 pb-5 text-center">
      <v-btn color="primary" @click="popup">{{msg.add_second_lang}}</v-btn>
    </div>
    <select-lang-modal v-if="showPopup" :event="event" @second-lang-created="secondLangCreated" @close="showPopup = false"></select-lang-modal>
  </div>
</template>

<script type="text/javascript">
import axios from '@/store/axios'
import SecondLangModal from '@/components/shared/manage_organization_components/event_manage_cmpnts/cmpnts/second_langu_modal'
import messages from '@/locales/no_second_lang.json'

export default {
  props: [
    "event"
  ],
  components: {
    'select-lang-modal': SecondLangModal
  },
  data: function () {
    return {
      showPopup: false,
    }
  },
  methods: {
    popup(){
      this.showPopup = true
    },
    secondLangCreated() {
      console.log("よかったね")
      this.event.update()
      this.showPopup = false
    }
  },
  mounted() {
  },
  computed: {
    msg() { return messages[this.$store.state.user.language] },
  }
}

</script>
