<template>
  <div>
    <v-app-bar app color="primary accent-4" dark shouldExtend>
      <v-toolbar-title>
        <v-btn depressed dark color ="primary accent-4" small router class="px-1" @click="segueToCurrentUniversityTop">
          <span class="display-1 text-capitalize" style="line-height: 1.5rem;">Univents</span>
        </v-btn>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on">
            <v-icon>language</v-icon> <span class="d-none d-md-block">Language</span>
          </v-btn>
      </template>
      <v-list>
        <v-list-item v-for="lang in languages" :key="lang.name" @click="changeLanguage(lang)">
          <v-list-item-title>{{ lang.emoji + lang.name }} <span v-if="user.language==lang.symbol">✔︎</span></v-list-item-title>
        </v-list-item>
      </v-list>
      </v-menu>

      <template v-for="item in items">
        <v-btn depressed color="primary accent-4 hidden-md-and-down" v-if="isShowItem(item)" :to="item.route" :key="item.route" >
          <span>{{item.title}}</span>
        </v-btn>
      </template>

      <v-app-bar-nav-icon class="hidden-lg-and-up" @click="drawer = !drawer"></v-app-bar-nav-icon>

      <template #extension v-if="shouldExtend">
        <v-toolbar-title v-if="shouldShowCurrentUniversity">
          <universities-select-dialog :university="university"></universities-select-dialog>
        </v-toolbar-title>
        <v-toolbar-title v-if="shouldShowSelectUniversity">
          <universities-select-dialog></universities-select-dialog>
        </v-toolbar-title>
      </template>

    </v-app-bar>

    <v-navigation-drawer disable-resize-watcher right v-model="drawer" app class="white darken-1">
      <v-list>
        <v-list-item  :class="item.color+'--text'" v-for="item in items" :key="item.title" router :to="item.route" v-if="isShowItem(item)">
          <!-- <v-list-item-action>
            <v-icon :class="item.color+'--text'">{{ item.icon }}</v-icon>
          </v-list-item-action> -->
          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>

        </v-list-item>
      </v-list>
    </v-navigation-drawer>


  </div>
</template>

<script>
import axios from '@/store/axios'
import UniversitiesSelectDialog from '@/components/shared/views/universities_select_dialog.vue'

export default {
    name: 'HeaderComponent',
    components: {
      "universities-select-dialog": UniversitiesSelectDialog
    },
    data() {
        return {
            drawer:false,
            items: [
            { title: 'All Events', route: '/', showType: "both" },
            { title: 'Login', route: '/login', showType: "noAuthed" },
            { title: 'SignUp', route: '/signup', showType: "noAuthed" },
            // { title: 'Logout', route: '', showType: "authed" },
            { title: 'My Organization(s)', route: '/manage', showType: "authed" },
            { title: 'Logout', route: '/logout', showType: "authed" },
          ],
          base_languages: [
            { name_ja: "日本語", name_en: "Japanese", symbol: "ja", emoji: "🇯🇵" },
            { name_ja: "英語", name_en: "English", symbol: "en", emoji: "🇺🇸" }
          ],
        }
    },
    computed: {
      languages() {
        return this.base_languages.map(function(lang){
          if( this.user.language == "ja" ){
            lang.name = lang.name_ja
          }else{
            lang.name = lang.name_en
          }
          return lang
        }.bind(this) )
      },
      isShowItem(item) {
        return function(item){
          switch (item.showType) {
            case "both":
              return true
              break
            case "authed":
              return this.user.authenticated
              break
            case "noAuthed":
              return !this.user.authenticated
              break
            default:
              return false
              break
          }
        }
      },
      shouldExtend(){
        return this.$store.state.header_state.type != "none"
      },
      shouldShowCurrentUniversity(){
        return this.$store.state.header_state.type == "university"
      },
      shouldShowSelectUniversity(){
        return this.$store.state.header_state.type == "selection"
      },
      university(){
        return this.$store.state.header_state.university
      }
    },
    methods: {
      changeLanguage(language) {
        if (this.user.authenticated) {
          // ログイン済みだったら
          axios.post('/api/v1/users/own/set_language').then(resp => {
            this.$store.commit('user/updateLanguage', language.symbol);
            window.location.reload() // 将来的にはmasterデータをupdateするだけとかにしたい
          })
        } else {
          // まだログインしていなければ
          this.$store.commit('user/updateLanguage', language.symbol);
          window.location.reload() // 将来的にはmasterデータをupdateするだけとかにしたい
        }
      }
    }
}
</script>

<style scoped>
  header {
    background: #EEE;
  }
</style>
