import axios from '@/store/axios'
import display_lang from '@/models/display_lang';

export default class Event {
  constructor(arg) {
    this.init()
    if ( typeof arg == "string" ) {
      this.id = arg
      this.fetch()
    }
    if ( typeof arg == "object" ) {
      Object.assign(this, arg)
      this.after_assign()
    }
  }

  fetch() {
    axios.get(`/api/v1/events/${this.id}`).then( resp => {
      var assignedData = this.preprocessAPIData(resp.data.event)
      Object.assign(this, assignedData)
      this.after_assign()
    })
  }

  after_assign(){
    this.set_language_apply_question_function()
    this.setLangParams()
    this.set_ids()
    this.set_dates()
    this.is_fetched = true
  }

  set_dates(){
    if (this.end_at) { this.end_at = new Date(this.end_at) }
    if (this.start_at) { this.start_at = new Date(this.start_at) }
    if (this.recieption_start_at) { this.recieption_start_at = new Date(this.recieption_start_at) }
    if (this.apply_due) { this.apply_due = new Date(this.apply_due) }
  }

  set_ids(){
    this.used_language_ids = this.used_languages.map(function(i){ return i.id })
    this.target_university_ids = this.target_universities.map(function(i){ return i.id })
    this.target_department_category_ids = this.target_department_categories.map(function(i){ return i.id })
    this.target_country_ids = this.target_countries.map(function(i){ return i.id })
    this.target_occupation_ids = this.target_occupations.map(function(i){ return i.id })
    this.target_prefecture_ids = this.target_prefectures.map(function(i){ return i.id })
  }

  init() {
    this.start_at = null
    this.end_at = null
    this.recieption_start_at = null
    this.apply_due = null
    this.max_participant_num = null
    this.prefecture_id = null
    this.event_purpose_id= null
    this.event_format_id = null
    this.is_published = true
    this.is_public = false
    this.is_online = false
    this.is_shown_only_organization_universities = null
    this.is_created_by_hf = null
    this.thumbnail_url = null
    this.detail_url = null
    this.language_events = [{
      language_id: 1,
      title: "",
      discription: "",
      address: "",
      place_name: "",
      organization_name: "",
      is_main: false,
    }],
    this.used_language_ids = []
    this.target_university_ids = []
    this.target_department_category_ids = []
    this.target_prefecture_ids = []
    this.target_country_ids = []
    this.target_occupation_ids = []
    this.apply_questions = [
      // {
      //   id = null,
      //   question_type_id = 1,
      //   index = null,
      //   language_apply_questions_attributes = [{
      //     language_id = 1,
      //     title = "テスト",
      //     discription = "テストだよ",
      //     options_data = []
      //   }]
      // }
    ]
    this.applications = []
    this.apply_users = []
    this.remind_event_mails = []
    this.organization = null
    this.prefecture = null
    this.event_purpose = null
    this.event_format = null
    // setLangでセットされる
    this.title = null
    this.discription = null
    this.address = null
    this.place_name = null
    // 状態管理用
    this.is_fetched = false
  }

  preprocessAPIData(data){
    data.apply_questions.forEach( apply_question =>  {
      // 全てのapply_questionに_destroyパラメータをつける
      apply_question._destroy = false
    })
    return data
  }

  add_apply_question() {
    // lang_apply_questionsを作成
    var lang_apply_questions = this.language_ids().map( lang_id => {
      return {
        language_id: lang_id,
        title: "",
        discription: "",
        options_data: []
      }
    })
    // 実際に追加
    this.apply_questions.push({
      id: null,
      question_type_id: 1,
      index: null,
      is_required: true,
      _destroy: false,
      language_apply_questions: lang_apply_questions,
      language_apply_question: function (lang_id) {
        return this.language_apply_questions.filter( lang_event => {
          return lang_event.language_id == lang_id
        } )[0]
      }
    })
  }

  place_text(){
    if ( this.is_online ) { return "Online" }
    else { return `${this.prefecture.name}, ${this.place_name}` }
  }

  set_language_apply_question_function() {
    this.apply_questions.forEach( apply_question =>  {
      // 全てのapply_questionにlanguage_apply_questionのメソッドを授ける
      apply_question.language_apply_question = function (lang_id) {
        return this.language_apply_questions.filter( lang_event => {
          return lang_event.language_id == lang_id
        } )[0]
      }
    })
  }

  update(){
    this.fetch()
  }

  language_event(lang_id) {
    return this.language_events.filter( lang_event => {
      return lang_event.language_id == lang_id
    } )[0]
  }

  main_language_event(){
    var main =  this.language_events.filter( lang_event => {
      return lang_event.is_main
    } )[0]
    return main
  }

  second_language_event(){
    return this.language_events.filter( lang_event => {
      return !lang_event.is_main
    } )[0]
  }

  language_ids(){
    var lang_array =  this.language_events.map(function (lang_event) {
      return lang_event.language_id
    })
    return lang_array
  }

  languages(){
    var lang_array =  this.language_events.map(function (lang_event) {
      return lang_event.language
    })
    return lang_array
  }

  start_at_date(){
    return new Date(this.start_at)
  }

  setLangParams(){
    var lang_id = display_lang(this.language_events)
    var lang_event = this.language_event(lang_id)
    this.title = lang_event.title
    this.discription = lang_event.discription
    this.address = lang_event.address
    this.place_name = lang_event.place_name
  }

  organization_name(){
    if ( this.is_created_by_hf ){
      var lang_id = display_lang(this.language_events)
      return this.language_event(lang_id).organization_name
    } else {
      return this.organization.language_organizations[0].name
    }
  }

  is_appliable(){
    if ( this.apply_due && this.apply_due < new Date() ) { return false  }
    if ( this.start_at && this.start_at < new Date() ) { return false  }
    return true
  }

  toPostedEvent() {
    var postedEvent = {}
    postedEvent.id = this.id
    postedEvent.start_at = this.start_at
    postedEvent.end_at = this.end_at
    postedEvent.recieption_start_at = this.recieption_start_at
    postedEvent.apply_due = this.apply_due
    postedEvent.max_participant_num = this.max_participant_num
    postedEvent.prefecture_id = this.prefecture_id
    postedEvent.event_purpose_id = this.event_purpose_id
    postedEvent.event_format_id = this.event_format_id
    postedEvent.is_published = this.is_published
    postedEvent.is_public = this.is_public
    postedEvent.is_online = this.is_online
    postedEvent.is_shown_only_organization_universities = this.is_shown_only_organization_universities
    postedEvent.thumbnail_url = this.thumbnail_url
    postedEvent.language_events_attributes = this.language_events
    postedEvent.used_language_ids = this.used_language_ids
    postedEvent.target_university_ids = this.target_university_ids
    postedEvent.target_department_category_ids = this.target_department_category_ids
    postedEvent.target_country_ids = this.target_country_ids
    postedEvent.target_occupation_ids = this.target_occupation_ids
    postedEvent.target_prefecture_ids = this.target_prefecture_ids
    postedEvent.detail_url = this.detail_url
    postedEvent.apply_questions_attributes = []
    this.apply_questions.forEach(function(aq){
      var aq_attr = {}
      aq_attr.id = aq.id
      aq_attr.question_type_id = aq.question_type_id
      aq_attr.index = aq.index
      aq_attr._destroy = aq._destroy
      aq_attr.is_required = aq.is_required
      aq_attr.language_apply_questions_attributes = aq.language_apply_questions
      postedEvent.apply_questions_attributes.push(aq_attr)
    })
    return postedEvent
  }
}
