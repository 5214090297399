import { parseISO } from "date-fns";

export default class {
  constructor(arg) {
    this.init();
    // if ( typeof arg == "string" ) {
    //   this.id = arg
    //   this.fetch()
    // }
    if (arg === undefined) return;
    if (typeof arg == "object") {
      Object.assign(this, arg);
      if (this.users) {
        this.user_ids.splice(0, this.user_ids.length);
        this.user_ids.push(...this.users.map(u => u.id));
      }
      if (this.send_at) this.send_at = parseISO(this.send_at);
      if (this.sent_at) this.sent_at = parseISO(this.sent_at);

      this.is_fetched = true;
    } else {
      throw new Error("invalid argument");
    }
  }

  // fetch() {
  //   console.log("fetching")
  //   axios.get(`/api/v1/events/${this.id}`).then( resp => {
  //     Object.assign(this, resp.data)
  //     this.is_fetched = true
  //   })
  // }

  init() {
    this.id = null;
    this.event_id = null;
    this.send_at = null;
    this.sent_at = null;
    this.user_ids = [];
    this.users = [];
    // 状態管理用
    this.is_fetched = false;
  }

  get send_at_str () {
    return this.send_at.yyyymmdd() + "  " + this.send_at.hhMM();
  }
  get sent_at_str() {
    return this.sent_at.yyyymmdd() + "  " + this.sent_at.hhMM();
  }

  // update(){
  //   this.fetch()
  // }

  toPostedNotifyEventMail() {
    return {
      id: this.id,
      event_id: this.event_id,
      send_at: this.send_at,
      sent_at: this.sent_at,
      user_ids: this.users.map(u => u.id)
    };
  }

}
