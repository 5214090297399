<template>
  <div class="">
    <v-form ref="form" lazy-validation>
      <v-row justify="center">
        <v-col cols="6">
          <v-text-field v-model="user_data.first_name" class="required" :rules="firstNameRules" :label="msg.first_name"></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field v-model="user_data.last_name" class="required" :rules="lastNameRules" :label="msg.last_name"></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12">
          <v-text-field v-model="user_data.email" placeholder="email@example.com" class="required" :rules="emailRules" :label="msg.email" required></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12">
          <v-autocomplete v-model="user_data.country_id" :items="master.countries" item-text="name" item-value="id" class="required" :rules="countryRules" :label="msg.country"></v-autocomplete>
          <a class="cf-iso" href="https://www.iso.org/obp/ui/#search/code/" target="_blank">{{ msg.cf_iso }}</a>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12">
          <v-select v-model="user_data.occupation_id" :items="master.occupations" item-text="name" item-value="id" class="required" :rules="occupationRules" :label="msg.occupation"></v-select>
        </v-col>
      </v-row>

      <template v-if="user_data.occupation_id && master.occupation_find(user_data.occupation_id).should_inquire_univ_info">
        <v-row justify="center">
          <v-col cols="12">
            <v-autocomplete v-model="university_prefecture_id" :items="master.prefectures" item-text="name" item-value="id" class="required" :rules="prefectureRules" :label="msg.prefecture_of_university"></v-autocomplete>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12">
            <v-select v-model="user_data.university_id" :items="university_options" item-text="name" item-value="id" class="required" :rules="universityRules" :label="msg.university"></v-select>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12">
            <v-select v-model="user_data.department_category_id" :items="master.department_categories" item-text="name" item-value="id" class="required" :rules="departmentCategoryRules" :label="msg.department"></v-select>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="6">
            <v-select v-model="user_data.graduation_year" :items="yearOptions" class="required" :rules="graduationYearRules" :label="msg.graduation_year"></v-select>
          </v-col>
          <v-col cols="6">
            <v-select v-model="user_data.graduation_month" :items="monthOptions" item-text="text" item-value="value" class="required" :rules="graduationMonthRules" :label="msg.graduation_month"></v-select>
          </v-col>
        </v-row>
      </template>
      <v-row justify="center">
        <v-col cols="12">
          <v-text-field v-model="user_data.password" type="password" class="required" :rules="passwordRules" :label="msg.password" required ></v-text-field>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import Master from '@/models/master'
import messages from '@/locales/sign_up_form.json'

export default {
  props: [
    'user_data',
  ],
  data() {
    return {
      master: new Master(),
      university_prefecture_id: null,
      firstNameRules: [ v => ( !!v ) || "First Name can't be blank" ],
      lastNameRules: [ v => ( !!v ) || "Last Name can't be blank" ],
      emailRules: [ v => ( !!v ) || "Email can't be blank", v => /.+@.+\..+/.test(v) || "E-mail must be valid" ],
      countryRules: [ v => ( !!v ) || "Country can't be blank" ],
      occupationRules: [ v => ( !!v ) || "Occupation can't be blank" ],
      prefectureRules: [ v => ( this.user_data.occupation_id != 1 || v != null ) || "Prefecture can't be blank" ],
      universityRules: [ v => ( this.user_data.occupation_id != 1 || v != null ) || "University can't be blank" ],
      departmentCategoryRules: [ v => ( this.user_data.occupation_id != 1 || !!v ) || "Department can't be blank" ],
      graduationYearRules: [ v => ( this.user_data.occupation_id != 1 || !!v ) || "Graduation year can't be blank" ],
      graduationMonthRules: [ v => ( this.user_data.occupation_id != 1 || !!v ) || "Graduation month can't be blank" ],
      passwordRules: [ v => ( !!v ) || "Password can't be blank", v => (!!v && v.length >= 6) || "Password must be more than 6 characters" ],
    }
  },
  mounted(){
    var other = this.$store.state.user.language == "ja" ? "その他" : "Other"
    this.master.universities.unshift({ id: "", name: other })
  },
  computed: {
    university_options() {
      return this.master.universities.filter( univ => {
        return this.university_prefecture_id == univ.prefecture_id
      } )
    },
    yearOptions () {
      return [...Array(8).keys()].map( i => {
        return new Date().getFullYear() + i
      })
    },
    monthOptions (){
      return [
        {text: "March", value: 3},
        {text: "September", value: 9}
      ]
    },
    msg(){
      return messages[this.$store.state.user.language]
    }
  },
  methods: {
    click() {

    },
  },
  watch: {
    'user_data.password': function(value) {
      this.user_data.password_confirmation = value
    }
  }
}

</script>

<style media="screen" scoped>
 .cf-iso {
   margin: 0 !important;
   float: right;
   font-size: 0.5em;
 }
</style>
