<template>
  <div>
    {{language_name}}
    <v-text-field :disabled="help_article.id" v-model="help_article.key" label="key"></v-text-field>
    <v-text-field v-model="help_article.language_help_article(lang_id).title" label="title"></v-text-field>
    <v-textarea v-model="help_article.language_help_article(lang_id).body" label="body" rows="20"></v-textarea>
  </div>
</template>

<script>
  export default {
    props:[
      "help_article",
      "lang_id"
    ],
    computed: {
      language_name(){
        if (this.lang_id==1){return "日本語"}
        if (this.lang_id==2){return "英語"}
      }
    }
  }
</script>
