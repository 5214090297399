<template>
  <v-container id="app">
    <h2>{{msg.organizations}}</h2>
    <div class="relative" style="position: relative; height: 50px;">
      <v-btn rounded color="success" right absolute class="mr-4" @click="$router.push('/manage/organizations/new')">{{msg.create}}</v-btn>
    </div>
       <v-card class="my-4"v-for="organization in organizations"  :key="organization.id" @click="$router.push(`/manage/organizations/${organization.id}`)"> <!--@click="$router.push(`/organizations/${organization.id}`) -->
         <v-card-title>{{ organization.language_organizations[0].name }}</v-card-title>
         <!-- <v-card-text>{{  }}</v-card-text> -->
         <v-card-actions>
           <v-btn color="primary">{{msg.to_organizaiton_manage}}</v-btn>
         </v-card-actions>
     </v-card>
    </div>

  </v-container>
</template>

<script type="text/javascript">
import axios from '@/store/axios'
import messages from '@/locales/manage.json'

export default {
  data: function () {
    return {
      organizations: []
    }
  },
  computed: {
    user() { return this.$store.state.user },
    msg() { return messages[this.$store.state.user.language] }
  },
  created () {
    // this.$store.dispatch('auth/destroy')
    this.$store.dispatch('user/update')
  },
  methods: {
  },
  mounted() {
    console.log("mounted")
    axios.get('/api/v1/organizations').then( resp => {
      console.log(resp)
      this.organizations = resp.data
    })
  }
}

</script>
