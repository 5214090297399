import VueRouter from 'vue-router';
import TopPage from './components/shared/top';
import UniversityTopPage from './components/shared/university_top';
import SignUp from './components/shared/signup';
import Login from './components/shared/login';
import Logout from './components/shared/logout';
import ResetPassword from './components/shared/reset_password';
import ChangePassword from './components/shared/change_password';
import EventDetail from './components/shared/event_detail';
import EventApply from './components/shared/event_apply';
import OrganizationDetail from './components/shared/organization_detail';
import Manage from './components/shared/manage';
import Unfollow from './components/shared/unfollow';
import StaticAboutUs from './components/shared/static/about_us';
import StaticAboutUnivents from './components/shared/static/about_univents';
import StaticGuideForOrganizers from './components/shared/static/guide_for_organizers';

import ManageOrganization from './components/shared/manage_organization';
import CreateOrganization from './components/shared/create_organization';
import OrgSelectLang from './components/shared/org_select_lang';
import OrgCreateLang from './components/shared/org_create_lang';
import OrgManageOrganizers from './components/shared/org_manage_organizers'
import OrgManageFollowers from './components/shared/org_manage_followers'

import ManageOrganizationEvents from './components/shared/manage_organization_components/events';
import ManageOrganizationEdit from './components/shared/manage_organization_components/edit';
import ManageOrganizationEventCreate from './components/shared/manage_organization_components/event_create';
import ManageOrganizationEventCreateSelectLang from './components/shared/manage_organization_components/event_create_cmp/select_lang';
import ManageOrganizationEventCreateLang from './components/shared/manage_organization_components/event_create_cmp/create_lang';

import ManageOrganizationEventManage from './components/shared/manage_organization_components/event_manage'
import ManageOrganizationEventManageEdit from './components/shared/manage_organization_components/event_manage_cmpnts/edit'
import ManageOrganizationEventManageAnnounce from './components/shared/manage_organization_components/event_manage_cmpnts/announce'
import ManageOrganizationEventManageRemind from './components/shared/manage_organization_components/event_manage_cmpnts/remind'
import ManageOrganizationEventManageList from './components/shared/manage_organization_components/event_manage_cmpnts/list'
import ManageOrganizationEventManageSlip from './components/shared/manage_organization_components/event_manage_cmpnts/slip'

import HelpArticleIndex from './components/shared/help_articles/index'
import HelpArticleShow from './components/shared/help_articles/show'
import HelpArticleNew from './components/shared/help_articles/new'
import HelpArticleEdit from './components/shared/help_articles/edit'

import store from '@/store'
import axios from '@/store/axios'

const router = new VueRouter({
  routes: [
    // Public
    { path: '/', component: TopPage },
    { path: '/u/:university_name', component: UniversityTopPage, props: true },
    { path: '/signup', component: SignUp, meta: { isNotLogin: true } }, // ログインしてる時に来て欲しくない
    { path: '/login', component: Login },
    { path: '/logout', component: Logout },
    { path: '/login/reset', component: ResetPassword },
    { path: '/password', component: ChangePassword },
    { path: '/events/:event_id', component: EventDetail, props: true },
    { path: '/events/:event_id/apply', component: EventApply, props: true  },
    { path: '/organizations/:id', component: OrganizationDetail, props: true },
    { path: '/organizations/:organization_id/unfollow', component: Unfollow, props: true },
    { path: '/help_articles/:mykey', component: HelpArticleShow, props: true },
    { path: '/static/about_us', component: StaticAboutUs, props: true },
    { path: '/static/about_univents', component: StaticAboutUnivents, props: true },
    { path: '/static/guide_for_organizers', component: StaticGuideForOrganizers, props: true },

    // Only Login User
    // path: '/account'

    // Only Organizer
    // Organizations
    { path: '/manage', component: Manage, meta: { requireAuth: true } },
    { path: '/manage/organizations/new', component: CreateOrganization, meta: { requireAuth: true } },
    { path: '/manage/organizations/:organization_id/select_lang', component: OrgSelectLang, meta: { requireOrgManagerAuth: true }, props: true },
    { path: '/manage/organizations/:organization_id/create_lang', component: OrgCreateLang, meta: { requireOrgManagerAuth: true }, props: true },
    // Events
    { path: '/manage/organizations/:organization_id', component: ManageOrganization, meta: { requireOrgManagerAuth: true }, props: true, name: "manage_organization",
      children: [
        { path: '', redirect: 'events' },
        { path: 'events', component:  ManageOrganizationEvents },
        { path: 'edit', component:  ManageOrganizationEdit },
        { path: 'event_create', component:  ManageOrganizationEventCreate },
        { path: 'event_create/:event_id/select_lang', component: ManageOrganizationEventCreateSelectLang, props: true },
        { path: 'event_create/:event_id/:lang_id', component: ManageOrganizationEventCreateLang, props: true },
        { path: 'members', component: OrgManageOrganizers, props: true },
        { path: 'followers', component: OrgManageFollowers, props: true }
      ]
    },
      // EventConfiguration
    { path: '/manage/organizations/:organization_id/events/:event_id', component: ManageOrganizationEventManage, meta: { requireEventManagerAuth: true }, props: true, name: "manage_event",
      children: [
        { path: '', redirect: 'edit' },
        { path: 'edit', component: ManageOrganizationEventManageEdit },
        { path: 'announce', component: ManageOrganizationEventManageAnnounce },
        { path: 'remind', component: ManageOrganizationEventManageRemind },
        { path: 'list', component: ManageOrganizationEventManageList },
        { path: 'slip', component: ManageOrganizationEventManageSlip },
      ]
    },
    // For Uniteam members management page
    { path: '/uniteamanage/help_articles', component: HelpArticleIndex, meta: { requireUniteamAuth: true } },
    { path: '/uniteamanage/help_articles/new', component: HelpArticleNew, meta: { requireUniteamAuth: true } },
    { path: '/uniteamanage/help_articles/:mykey/edit', component: HelpArticleEdit, meta: { requireUniteamAuth: true }, props: true },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition != null) { return savedPosition }
    else { return { x: 0, y: -100 } }
  }
})

// beforeEachの中でuser/ownでauthした後に呼ばれるやつ
router.beforeEachWithUser = function (to, from, next, user) {
  store.commit('header_state/hideFromHeader')

  // ログインしている時に来て欲しくない
  if ( to.matched.some(record => record.meta.isNotLogin)) {
    if (!user.authenticated) {
      next()
    }else{
      next({path: '/', query: { redirect_to: to.fullPath } })
    }
  }

  // ログインしている時だけ来てほしい
  else if ( to.matched.some(record => record.meta.requireAuth) ) {
    if (user.authenticated) {
      next()
    }else{
      next({path: '/', query: { redirect_to: to.fullPath } })
    }
  }

  // 団体のマネージャーだけ来てほしい
  else if ( to.matched.some(record => record.meta.requireOrgManagerAuth) ) {
    axios.get(`/api/v1/authentications/organization/${to.params.organization_id}`).then( resp => {
      next()
    }).catch(resp=>{
      next({path: '/', query: { redirect_to: to.fullPath } })
    })
  }

  // イベントの管理できる人だけ来てほしい
  else if ( to.matched.some(record => record.meta.requireEventManagerAuth) ) {
    axios.get(`/api/v1/authentications/event/${to.params.event_id}`).then( resp => {
      next()
    }).catch(resp=>{
      next({path: '/', query: { redirect_to: to.fullPath } })
    })
  }

  else if ( to.matched.some(record => record.meta.requireUniteamAuth) ) {
    axios.get(`/api/v1/authentications/uniteam`).then ( resp => {
      next()
    } ).catch(resp=>{
      next({path: '/', query: { redirect_to: to.fullPath } })
    })
  }

  // 誰でも入っていいよ
  else {
    next()
  }
}

// 本当はここでbeforeEachを回している
router.beforeEach((to, from, next) => {
  axios.get("/api/v1/users/own").then(resp=>{
    // userデータの初期化
    store.commit("user/update", resp.data.data)
    var user = store.state.user
    router.beforeEachWithUser(to, from, next, user)
  } ).catch(resp=>{
    store.commit("user/markUnauthenticated")
    var user = store.state.user
    router.beforeEachWithUser(to, from, next, user)
  } )
});


export default router;
