<template>
    <div>
      <v-btn large color="primary" @click="$router.push(`/manage/organizations/${organization.id}/event_create`)">
        <v-icon left>mdi-plus</v-icon>
        {{msg.event_create}}
      </v-btn>
      <h2 class="mt-5">{{msg.future_events}}</h2>
       <v-card class="my-4" v-for="f_event in f_events"  :key="f_event.id" @click="$router.push(`/manage/organizations/${organization.id}/events/${f_event.id}`)">
          <v-card-title>{{ f_event.title }}</v-card-title>
          <v-card-text>{{ format(f_event.start_at, "PPP") }}</v-card-text>
          <v-card-actions>
            <v-btn v-on:click.stop="$router.push(`/events/${f_event.id}`)" color="primary">{{msg.preview}}<v-icon>remove_red_eye</v-icon></v-btn>
          </v-card-actions>
      </v-card>
      <h2 class="mt-5">{{msg.past_events}}</h2>
      <v-card class="my-4" v-for="p_event in p_events"  :key="p_event.id" @click="$router.push(`/manage/organizations/${organization.id}/events/${p_event.id}`)">
         <v-card-title>{{ p_event.title }}</v-card-title>
         <v-card-text>{{ format(p_event.start_at, "PPP") }}</v-card-text>
         <v-card-actions>
           <v-btn v-on:click.stop="$router.push(`/events/${p_event.id}`)" color="primary">{{msg.preview}}<v-icon>remove_red_eye</v-icon></v-btn>
         </v-card-actions>
     </v-card>
    </div>
</template>

<script type="text/javascript">
import axios from '@/store/axios'
import messages from '@/locales/events.json'
import Event from '@/models/event'

export default {
  props: [
    'organization'
  ],
  data: function () {
    return {
      events: []
    }
  },
  computed: {
    user() { return this.$store.state.user },
    msg() { return messages[this.$store.state.user.language] },
    f_events() {
      return this.events.filter(function(value){
        return !(value.start_at_date().isPast())
      })
    },
    p_events() {
      return this.events.slice().reverse().filter(function(value){
        return value.start_at_date().isPast()
      })
    }
  },
  created () {
    axios.get(`/api/v1/organizations/${this.organization.id}/events`).then( resp => {
      resp.data.forEach(function(val){
        var event = new Event(val)
        this.events.push(event)
        this.events.sort(function(a, b) {
          if(a.start_at_date().getTime() > b.start_at_date().getTime()){
            return 1
          }else{
            return -1
          }
        })
      }.bind(this))
    })
  },
  // methods: {
  // },
  // mounted() {
  //   console.log("mounted")
  //   axios.get('/api/v1/organizations/').then( resp => {
  //     console.log(resp)
  //     this.organizations = resp.data
  //   })
  // }
}

</script>
