import axios from '@/store/axios'
import display_lang from '@/models/display_lang';
import University from '@/models/university'

export default class UniversitiesCollection extends Array {
  constructor(arg) {
    super()
    if ( typeof arg == "string" ) {
      axios.get(arg).then(resp=>{
        resp.data.forEach(function(val){
          var univ = new University(val)
          this.push(univ)
        }.bind(this))
      })
    }
  }
}
