<template>
  <div id="app">
    <v-container>
      <v-row>

        <v-col cols="6">
          <h3 class="mb-3">{{ master.language_find(organization.main_language_organization()?.language_id)?.name }}</h3>
          <organization-form :organization="organization" :master="master" :organization_lang_id="organization.main_language_organization()?.language_id" @submit="submit"></organization-form>
        </v-col>

        <v-col cols="6">
          <h3 class="mb-3">{{ master.language_find(organization.second_language_organization()?.language_id)?.name }}</h3>
          <organization-form :organization="organization" :master="master" :organization_lang_id="organization.second_language_organization()?.language_id" @submit="submit"></organization-form>
        </v-col>
        <v-col class="text-right">
          <v-btn color="success" @click="submit">登録</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script type="text/javascript">
import axios from '@/store/axios'
import Organization from '@/models/organization'
import OrganizationForm from './organization_form'
import Master from '@/models/master'

export default {
  props: [
    "organization_id",
  ],
  components: {
    'organization-form': OrganizationForm
  },
  data: function () {
    return {
      organization: new Organization(this.organization_id),
      master: new Master()
    }
  },
  computed: {
    user() { return this.$store.state.user },
  },
  methods: {
    submit() {
      axios.put(`/api/v1/organizations/${this.organization_id}/update`, {organization: this.organization.toPostedOrganization()}).then( resp => {
        console.log(resp)
        this.$router.push(`/manage/organizations/${this.organization_id}`)
      })
    }
  },
  mounted() {
  }
}

</script>
