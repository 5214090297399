<template>
  <v-footer padless>
    <v-card width="100%">
      <v-row justify="center">
        <v-btn text rounded class="ma-3" v-for="link in links" :to="link.link">{{link.text}}</v-btn>
      </v-row>
      <v-card-actions class="grey--text justify-center pa-1">
        &copy;2019 — <strong>Univents</strong>
      </v-card-actions>
    </v-card>
  </v-footer>
</template>

<script>
  export default {
    data(){
      return {
        links: [
          { text: "About", link: "/static/about_univents" },
          { text: "For Organizers", link: "/static/guide_for_organizers" },
          { text: "About Us", link: "/static/about_us" },
        ]
      }
    }
  }
</script>


<style scoped>
  header {
    background: #EEE;
  }
</style>
