import axios from '@/store/axios'

export default class  {

  constructor(arg) {
    this.init()
    if ( typeof arg == "string" ) {
      this.setName(arg)
      this.fetch()
    }
    if ( typeof arg == "object" ) {
      Object.assign(this, arg)
      this.is_fetched = true
    }
  }

  fetch() {
    axios.get(`api/v1/masters/universities/${this.name_en}`).then(resp=>{
      Object.assign(this, resp.data)
      this.is_fetched = true
    })
  }

  init() {
    this.id = null
    this.name_en = null
    this.name_ja = null
    this.prefecture_id = null
    // 状態管理用
    this.is_fetched = false
  }

  setName(name){
    var university_name = name
    if (university_name.match(/_/)) { university_name = university_name.replace(/_/g, " ") }
    this.name_en = university_name
  }

  snake_name() {
    return this.name_en.replace(/ /g, "_")
  }

}
