<template>
  <div :class="required">
    <h4 class="sub-title">{{ question.language_apply_question(lang_id).title }}</h4>
    <v-textarea v-model="answers[question.id]" :rules="rules" rows="3">
    </v-textarea>
  </div>
</template>

<script>

export default {
  props: [
    "question",
    "answers",
    'lang_id'
  ],
  data(){
    return {
      rules: [ v => ( !this.question.is_required || !!v ) || "This is a required question" ],
    }
  },
  computed: {
    required() {
      if (this.question.is_required) {
        return "required"
      }
    }
  }
}

</script>
