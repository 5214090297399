import axios from '@/store/axios'
import display_lang from '@/models/display_lang';

export default class HelpArticle {
  constructor(arg) {
    this.init()
    console.log(typeof arg)
    if ( typeof arg == "string" ) {
      this.key = arg
      this.fetch()
    }
    if ( typeof arg == "object" ) {
      Object.assign(this, arg)
      this.is_fetched = true
      this.setLangParams(this.current_lang)
    }
  }

  fetch() {
    console.log("fetching")
    axios.get(`/api/v1/help_articles/${this.key}`).then( resp => {
      console.log(resp.data);
      Object.assign(this, resp.data)
      this.is_fetched = true
      this.setLangParams(this.current_lang)
    })
  }

  init() {
    this.key = null
    this.is_published = false
    this.is_deleted = false
    this.last_updated_user = null
    this.language_help_articles = [,
      {
        id: null,
        language_id: 1,
        title: "",
        body: "",
      },
      {
        id: null,
        language_id: 2,
        title: "",
        body: "",
      }
    ],
    // setLangでセットされる
    this.title = ""
    this.body = ""
    // 状態管理用
    this.is_fetched = false
    this.current_lang = null
  }

  update(){
    this.fetch()
  }

  setLangParams(id){
    var lang_id = display_lang(this.language_help_articles)
    var lang_art = this.language_help_article(lang_id)
    this.title = lang_art.title
    this.body = lang_art.body
    this.current_lang = id
  }

  language_help_article(lang_id) {
    return this.language_help_articles.filter( lang_org => {
      return lang_org.language_id == lang_id
    } )[0]
  }

  save(){
    if ( this.id ){ return this.update() }
    else { return this.create() }
  }

  //privateにしたい
  create(){
    return axios.post(`/api/v1/help_articles`, { help_article: this.toPostedObject() } )
  }
  update(){
    return axios.put(`/api/v1/help_articles/${this.id}`, { help_article: this.toPostedObject() } )
  }


  toPostedObject() {
    var postedObject = {}
    postedObject.id = this.id
    postedObject.key = this.key
    postedObject.is_published = this.is_published
    postedObject.is_deleted = this.is_deleted
    postedObject.language_help_articles_attributes = this.language_help_articles
    return postedObject
  }
}
