<template>
  <modal @close="$emit('close')">
    <h3 slot="header">{{msg.button_choose_language}}</h3>
    <div slot="body">
      <v-snackbar v-model="snackbar" :timeout="1500" top color="error">
        {{msg.button_choose_language}}
      </v-snackbar>
      <v-form class="mb-5">
        <v-radio-group v-model="selected_lang_id">
          <v-radio v-for="lang in master.commonly_used_lanugages()" :value="lang.id" :label="lang.name" v-if="event.language_ids().indexOf(lang.id) < 0"></v-radio>
        </v-radio-group>
        <div class="">
          <h4>{{msg.other}}</h4>
          <v-select :label="msg.button_choose_language" v-model="selected_lang_id" :items="master.not_commonly_used_lanugages()" item-value="id" item-text="name"></v-select>
        </div>
      </v-form>
      <v-btn @click="submit" color="success">{{msg.button_language}}</v-btn>
      <v-btn @click="$emit('close')">{{msg.button_later}}</v-btn>
    </div>
    <div slot="footer" style="min-height=30px;">

    </div>
  </modal>
</template>

<script>
import Modal from '@/components/shared/views/modal';
import axios from '@/store/axios'
import Master from "@/models/master"
import messages from '@/locales/select_lang.json'

export default {
  components: {
    Modal
  },
  props: [
    "showModal",
    "event"
  ],
  data() {
    return {
      selected_lang_id: null,
      snackbar: false,
      master: new Master(),
    }
  },
  computed: {
    organization_id(){ return this.event.organization_id },
    event_id(){ return this.event_id },
    msg() { return messages[this.$store.state.user.language] },
  },
  methods: {
    submit() {
      if (this.selected_lang_id) {
        axios.post(`/api/v1/language_events/create`, this.posted()).then(resp => {
          this.$emit('second-lang-created')
        })
      }else{
        this.snackbar = true
      }
    },
    posted() {
      return {
        event_id: this.event.id,
        language_id: this.selected_lang_id
      }
    }
  }
}
</script>
