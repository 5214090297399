<template>
  <div id="app">
    <v-snackbar v-model="snackbar" :timeout="1500" top color="success">
      {{snackbarText}}
    </v-snackbar>
    <v-container>
      <v-row>

        <v-col cols="6">
          <h3 class="mb-3">{{ master.language_find(event.main_language_event().language_id).name }}</h3>
          <event-form ref="main_form" :event="event" :event_lang_id="event.main_language_event().language_id"></event-form>
        </v-col>

        <v-col cols="6">
          <h3 class="mb-3" v-if="event.second_language_event()">{{ master.language_find(event.second_language_event().language_id).name }}</h3>
          <event-form ref="second_form" v-if="event.second_language_event()" :event="event" :event_lang_id="event.second_language_event().language_id"></event-form>
          <no-second-lang v-if="!event.second_language_event()" :event="event"></no-second-lang>
        </v-col>

      </v-row>

      <v-row justify="center">
        <v-col cols="4">
          <v-btn color="success" block @click="submit">{{msg.save}}</v-btn>
        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script type="text/javascript">
import axios from '@/store/axios'
import EventForm from '@/components/shared/manage_organization_components/event_form.vue'
import Event from '@/models/event'
import NoSecondLang from '@/components/shared/manage_organization_components/event_manage_cmpnts/cmpnts/no_second_lang'
import Master from '@/models/master'
import messages from '@/locales/event_form.json'

export default {
  props: [
    "organization_id",
    "event_id",
    "event"
  ],
  components: {
    'event-form': EventForm,
    'no-second-lang': NoSecondLang
  },
  data: function () {
    return {
      'snackbar': false,
      'snackbarText': null,
      'master': new Master(),
    }
  },
  computed: {
    user() { return this.$store.state.user },
    main_form() {return this.$refs.main_form.$refs.form},
    second_form() {return this.$refs.second_form ? this.$refs.second_form.$refs.form : undefined},
    msg() { return messages[this.$store.state.user.language] },
  },
  methods: {
    submit() {
      if ( this.main_form.validate() && ( !this.second_form || this.second_form.validate()) ){
        axios.put(`/api/v1/events/${this.event_id}/update`, {event: this.event.toPostedEvent()}).then( resp => {
          console.log(resp)
          this.event.update()
          this.flash("Event was successfully updated", "success")
        }).catch( err => {
          var key = Object.keys(err.response.data)[0]
          this.flash(key+" "+err.response.data[key][0], "error")
        } )
      }
    }
  },
  mounted() {
  }
}

</script>
