<template>
  <v-container v-if="events.length != 0">
    <v-row>
      <v-flex xs12 md4 v-for="event in events" :key="event.id" class="my-3">
        <v-card @click="$router.push(`/events/${event.id}`)" class="mx-3">
          <v-img class="white--text" :src="event.thumbnail_url" max-height="200" />
          <v-container>
              <v-card-subtitle class="pb-0">
                <span class="title font-weight-bold red--text">{{ format(event.start_at, "do") }}</span>
                <span class="title font-weight-bold">{{ format(event.start_at, "MMM") }}</span>
              </v-card-subtitle>
              <v-card-title class="pt-0">{{ event.title }}</v-card-title>
            <v-divider />
            <v-row>
              <v-col cols="12" class="pl-5 subtitle-1">
                <p class="mb-2"><v-icon class="pr-2" size="25">person</v-icon> {{ event.organization_name() }} <br></p>
                <p class="mb-2"><v-icon class="pr-2" size="25">event</v-icon> {{ event.start_at_date().hhMM() }} ~ <br></p>
                <p class="mb-2"><v-icon class="pr-2" size="25">assistant_photo</v-icon> {{ event.place_text() }} <br></p>
                <p class="mb-2"><v-icon class="pr-2" size="25">language</v-icon> {{ msg.hold_in }} {{ event.used_languages.map(l=>{return l.name}).join(", ") }}</p>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-flex>
    </v-row>
  </v-container>
  <p v-else class="grey--text text--lighten-1 mx-auto">{{ msg.no_events }}</p>
</template>

<script type="text/javascript">
import messages from '@/locales/top.json'

export default {
  props: [
    "events"
  ],
  computed: {
    user() { return this.$store.state.user },
    msg() { return messages[this.$store.state.user.language] },
  },
}

</script>
