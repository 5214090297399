<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <help-article-form :help_article="help_article" :lang_id="1"></help-article-form>
      </v-col>
      <v-col cols="6">
        <help-article-form :help_article="help_article" :lang_id="2"></help-article-form>
      </v-col>
    </v-row>
    <v-btn @click="submit">保存</v-btn>
  </v-container>
</template>

<script>
import HelpArticle from '@/models/help_article'
import HelpArticleForm from './form'

export default {
  components: {
    HelpArticleForm
  },
  data(){
    return {
      help_article: new HelpArticle()
    }
  },
  methods: {
    submit(){
      this.help_article.save().then(resp=>{
        var key = resp.data.data.key
        this.$router.push(`/uniteamanage/help_articles/${key}/edit`)
      })
    }
  }
}
</script>
