<template>
  <div class="app">
    <v-container>
      <h3 class="mb-4">{{msg.title}}</h3>
      <p>{{msg.subdescription}}</p>
      <div v-if="event.remind_event_mails.length == 0">
        <!-- 登録してある参加者へのメールがない場合 -->
        <v-btn color="primary" @click="showModal()">{{msg.button_set_mail}}</v-btn>
      </div>
      <div v-if="event.remind_event_mails.length >= 1">
        <!-- 登録してある参加者へのメールがある場合 -->
        <v-data-table :headers="headers" :items="remind_event_mails" :sort-by="['send_at']" must-sort disable-pagination hide-default-footer class="elevation-1 mb-4">
          <template v-slot:item.send_at="{ item }">
            {{ item.send_at_date().yyyymmdd() + "| " + item.send_at_date().hhMM()}}
          </template>
          <template v-slot:item.action="{ item }">
            <v-btn text outlined @click="showModal(item)" v-if="item.sent_at == null">{{msg.edit}}</v-btn>
            <v-btn text outlined @click="showModal(item)" v-if="item.sent_at != null">{{msg.check}}</v-btn>
          </template>
        </v-data-table>
        <v-btn @click="showModal()" color="primary">{{msg.button_add_mail}}</v-btn>
      </div>
    </v-container>
    <remind-mail-form-modal v-if="isShowModal" :remind_event_mail="setted_remind_event_mail" :event="event" @close="isShowModal=false"></remind-mail-form-modal>
  </div>
</template>

<script>
import axios from '@/store/axios'
import RemindEventMail from '@/models/remind_event_mail'
import RemindEventMailFormModal from '@/components/shared/manage_organization_components/event_manage_cmpnts/cmpnts/remind-mail-form-modal.vue'
import messages from '@/locales/remind.json'

export default {
  components: {
    'remind-mail-form-modal': RemindEventMailFormModal
  },
  props: [
    "event",
  ],
  data(){
    return {
      isShowModal: false,
      setted_remind_event_mail: null, //モーダルに持っていく用
      headers: [
        {text: "日時", value: "send_at", align: 'left'},
        {text: "内容", value: "custom_body", sortable: false},
        {text: "", value: "action", align: "center", sortable: false}
      ]
    }
  },
  computed: {
    remind_event_mails(){
      return this.event.remind_event_mails.map(obj => { return new RemindEventMail(obj) })
    },
    msg() { return messages[this.$store.state.user.language] },
  },
  methods: {
    showModal(mail){
      if (mail) {
        this.setted_remind_event_mail =  mail
      }else{
        console.log(" ya")
        this.setted_remind_event_mail = new RemindEventMail()
      }
      this.isShowModal = true
    }
  }
}
</script>
