<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <help-article-form :help_article="help_article" :lang_id="1"></help-article-form>
      </v-col>
      <v-col cols="6">
        <help-article-form :help_article="help_article" :lang_id="2"></help-article-form>
      </v-col>
      <v-col>
        <v-btn color="success" @click="submit">保存</v-btn>
        <v-btn @click="preview">プレビュー</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import HelpArticle from '@/models/help_article'
import HelpArticleForm from './form'

export default {
  props:[
    "mykey"
  ],
  components: {
    HelpArticleForm
  },
  data(){
    return {
      help_article: new HelpArticle(this.mykey)
    }
  },
  methods: {
    submit(){
      this.help_article.save()
    },
    preview(){
      this.help_article.save().finally(()=>{
        this.$router.push(`/help_articles/${this.mykey}`)
      })
    }
  }
}
</script>
