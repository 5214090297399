<template>
  <v-container>

    <my-snackbar ref="snackbar"></my-snackbar>

    <h3>{{ msg.header }}</h3>　<!-- TODO -->
    <p style="white-space:pre-wrap; word-wrap:break-word;">{{ msg.subdescription }}</p>

    <div v-if="notify_mail.id">
      <template v-if="!notify_mail.sent_at">
        <h5>{{ msg.schedule }}</h5>
        <p>
          {{ notify_mail.send_at_str }} <br>
          {{ notify_mail.users.length }} {{ msg.followers }}
        </p>
      </template>
      <template v-else>
        <h5>{{ msg.sent }}</h5>
        <p>
          {{ notify_mail.sent_at_str }} <br>
          {{ notify_mail.users.length }} {{ msg.followers }}
        </p>
      </template>
    </div>

    <div v-if="!notify_mail.id">
      <v-btn @click="showModal = true">{{ msg.set }}</v-btn>
    </div>
    <div v-else-if="!notify_mail.sent_at">
      <v-btn @click="showModal = true" color="primary">{{ msg.edit }}</v-btn>
      <v-btn @click="destroy" color="error">{{ msg.delete }}</v-btn>
    </div>

    <modal v-if="showModal" @close="showModal = false">

      <h3 slot="header">{{ msg.please_select }}</h3>
      <div slot="body">
        <VCheckbox :label="msg.filterByEnrolledStudents" v-model="filterByEnrolledStudents" />
        <div style="overflow-x: scroll; padding-left: 1px;">
          <div :style="{ width: table_width }">
            <v-data-table v-model="notify_mail.users" :headers="headers"
              :items="followersWithGraduationDate" :search="search" show-select calculate-widths no-data-text="Empty"
              :items-per-page="500" class="elevation-1 my-5" id="followers_table" :height="312"
              :hide-default-footer="true">
              <template v-slot:top>
                <v-text-field append-icon="search" label="Search" single-line hide-details v-model="search"
                  class="col-3"></v-text-field>
              </template>
            </v-data-table>
          </div>
        </div>
        <v-form>
          <v-datetime-picker label="Send at" format="YYYY-MM-DD HH:mm"
            v-model="notify_mail.send_at"></v-datetime-picker>
        </v-form>
        <v-btn @click="submit">{{ msg.set }}</v-btn>
      </div>
    </modal>

  </v-container>
</template>

<script type="text/javascript">
import axios from '@/store/axios'
import NotifyEventMail from '@/models/notify_event_mail'
import messages from '@/locales/announce.json'
import column_messages from '@/locales/sign_up_form.json'
import Modal from '@/components/shared/views/modal';
import MySnackbar from '@/components/shared/views/my_snackbar'
import { isBefore, lastDayOfMonth } from 'date-fns';

export default {
  props: [
    "organization_id",
    "event_id",
    "event"
  ],
  components: {
    Modal,
    MySnackbar
  },
  data: function () {
    return {
      showModal: false,
      error_messages: [],
      search: "",
      followers: [],
      notify_mail: new NotifyEventMail(),
      filterByEnrolledStudents: false,
    }
  },
  computed: {
    headers() {
      return [
        { text: this.col_msg.first_name, value: "first_name", sortable: false },
        { text: this.col_msg.last_name, value: "last_name", sortable: false },
        { text: this.col_msg.country, value: "country.name" },
        { text: this.col_msg.occupation, value: "occupation.name" },
        { text: this.col_msg.university, value: "university.name" },
        { text: this.col_msg.department, value: "department_category.name" },
        { text: this.col_msg.graduationDate, value: "graduationDate" },
      ]
    },
    modal_headers() {
      return [
        { text: this.col_msg.first_name, value: "first_name", sortable: false },
        { text: this.col_msg.last_name, value: "last_name", sortable: false },
      ]
    },
    user() { return this.$store.state.user },
    followersWithGraduationDate() {
      return this.followers.map(f => {
        const graduationDate = this.user.language == "ja" ? (
          // f.graduation_year: string | nullとf.graduation_month: string | nullを使って，""または"2024年"または"2024年3月"の形式に変換する
          f.graduation_year ? f.graduation_year + "年" + (f.graduation_month ? f.graduation_month + "月" : "") : ""
        ) : (
          // f.graduation_year: string | nullとf.graduation_month: string | nullを使って，""または"2024"または"2024-3"の形式に変換する
          f.graduation_year ? f.graduation_year + (f.graduation_month ? "-" + f.graduation_month : "") : ""
        )
        return { ...f, graduationDate }
      }).filter(this.tableFilter)
    },
    table_width() {
      var count = 0
      this.headers.forEach(header => {
        count += header.text.length
      })
      var ratio = 25
      if (this.$store.state.user.language == "ja") { ratio = 120 }
      return count * ratio + "px"
    },
    msg() { return messages[this.$store.state.user.language] },
    col_msg() { return column_messages[this.$store.state.user.language] },
  },
  methods: {
    getFollowers() {
      axios.get(`/api/v1/organizations/${this.organization_id}/followers`).then(resp => {
        this.followers = resp.data
      })
    },
    tableFilter(item) {
      // tableFilter(value: any, search: string | null, item: any) => boolean
      // applicationを持っているfollowerは除外する
      const hasApplication = this.event.applications.some(({user_id})=> user_id == item.id)


      // filterByEnrolledStudentsがtrueの場合、graduationDateがイベント開始時を過ぎているfollowerは除外する
      const graduationDateIsBeforeEventStart = ((graduationYear, graduationMonth) => {
        if (!graduationYear) return true // 卒業日が未設定の人は卒業済みとして扱う
        const graduationDate = lastDayOfMonth(new Date(graduationYear, graduationMonth ? graduationMonth - 1 : 0))
        return isBefore(graduationDate, this.event.start_at)
      })(item.graduation_year, item.graduation_month)

      return !(
        hasApplication ||
        (this.filterByEnrolledStudents && graduationDateIsBeforeEventStart)
      )
    },
    submit() {
      if (this.notify_mail.id == null) {
        this.create()
      } else {
        this.update()
      }
    },
    destroy() {
      if (confirm("Do you really want to delete?")) {
        axios.delete(`/api/v1/events/${this.event_id}/notify_event_mails/`).then(resp => {
          this.notify_mail = new NotifyEventMail()
          this.$refs.snackbar.showSuccess(this.msg.delete_notice)
        }).catch(resp => {
          this.$refs.snackbar.showError(this.msg.error_notice)
        })
      }
    },
    create() {
      axios.post(`/api/v1/events/${this.event_id}/notify_event_mails/`, { notify_mail: this.notify_mail.toPostedNotifyEventMail() }).then(resp => {
        this.showModal = false
        this.notify_mail = new NotifyEventMail(resp.data.notify_event_mail)
        this.$refs.snackbar.showSuccess(this.msg.set_notice)
      }).catch(resp => {
        this.$refs.snackbar.showErrors(resp.response.data.errors)
      })
    },
    update() {
      axios.put(`/api/v1/events/${this.event_id}/notify_event_mails/`, { notify_mail: this.notify_mail.toPostedNotifyEventMail() }).then(resp => {
        this.showModal = false
        this.notify_event_mail = new NotifyEventMail(resp.data.notify_event_mail)
        this.$refs.snackbar.showSuccess(this.msg.update_notice)
      }).catch(resp => {
        this.$refs.snackbar.showErrors(resp.response.data.data)
      })
    },
    init_notify_mail() {
      axios.get(`/api/v1/events/${this.event_id}/notify_event_mails/`).then(resp => {
        this.notify_mail = new NotifyEventMail(resp.data)
      })
    },
  },
  mounted() {
    this.getFollowers()
    this.init_notify_mail()
  }
}

</script>
