<template>
  <div id="app">
    <h3>登録されているユーザー</h3>
    <v-data-table :headers="headers" :items="organizers" calculate-widths hide-default-footer :items-per-page="500" class="elevation-1 my-5">
      <template v-slot:[`item.remove`]="{ item }">
        <v-btn
        small
        color="error"
        @click="showModalRemoveAdmin=true; removeAdminID=item.id"
        v-if="item.id != user.id && organizers.length > 1"
        >
          {{ col_msg.remove }}
        </v-btn>
        <modal v-if="showModalRemoveAdmin" @close="showModalRemoveAdmin = false">
          <h3 slot="header">{{ col_msg.dialog_title }}</h3>
          <div slot="body">
            <p>{{ col_msg.dialog_description }}</p>
            <v-btn @click="removeUser(removeAdminID)">{{ col_msg.dialog_accept }}</v-btn>
          </div>
        </modal>
      </template>
    </v-data-table>
    <v-btn @click="showModal=true">追加</v-btn>
    <modal v-if="showModal" @close="showModal = false">
      <h3 slot="header">追加するユーザーのメールアドレスを打ってください</h3>
      <div slot="body">
        <p>ユーザーはUniventsにSignUpしている必要があります。</p>
        <v-text-field label="Email" v-model="new_organizer_email"></v-text-field>
        <v-btn @click="submit">追加</v-btn>
      </div>
    </modal>
  </div>
</template>

<script type="text/javascript">
import axios from '@/store/axios'
import Organization from '@/models/organization'
import OrganizationForm from '@/components/shared/organization_form'
import NoSecondLang from '@/components/shared/manage_organization_components/no_second_lang'
import Master from '@/models/master'
import column_messages from '@/locales/sign_up_form.json'
import Modal from '@/components/shared/views/modal';

export default {
  components: {
    Modal
  },
  props: [
    "organization",
  ],
  data: function () {
    return {
      organizers: [],
      showModal: false,
      showModalRemoveAdmin: false,
      removeAdminID: null,
      new_organizer_email: "",
      dialog: false,
    }
  },
  computed: {
    user() { return this.$store.state.user },
    headers(){
      return [
        {text: this.col_msg.first_name, value: "first_name"},
        {text: this.col_msg.last_name, value: "last_name"},
        {text: "", value: "remove"},
      ]
    },
    col_msg() { return column_messages[this.$store.state.user.language] },
  },
  methods: {
    submit() {
      axios.post(`/api/v1/organizations/${this.organization.id}/organization_users/create`, {new_organizer_email: this.new_organizer_email}).then( resp => {
        this.showModal = false
        this.getOrganizers()
      })
    },
    getOrganizers(){
      axios.get(`/api/v1/organizations/${this.organization.id}/organization_users`).then(resp => {
        console.log(resp);
        this.organizers = resp.data
      })
    },
    removeUser(removeAdminID){
      axios.delete(`/api/v1/organizations/${this.organization.id}/organization_users/${removeAdminID}`).catch( err => {
        this.flash("エラーが発生しました", "error")
      }).finally( () => {
        this.removeAdminID = null
        this.showModalRemoveAdmin = false
        this.getOrganizers()
      })
    },
  },
  mounted() {
    this.getOrganizers()
  }
}

</script>
