<template>
  <div :class="required">
    <h4 class="sub-title">{{ question.language_apply_question(lang_id).title }}</h4>
    <v-radio-group  v-model="answers[question.id]" :rules="rules">
      <v-radio v-for="(option, index) in question.language_apply_question(lang_id).options_data" class="mt-2" :label="question.language_apply_question(lang_id).options_data[index]" v-bind:value="question.language_apply_question(lang_id).options_data[index]">
      </v-radio>
    </v-radio-group>
  </div>
</template>

<script>

export default {
  props: [
    "question",
    "answers",
    "lang_id"
  ],
  data(){
    return {
      rules: [ v => ( !this.question.is_required || !!v ) || "This is a required question" ],
    }
  },
  methods: {
    addOption() {
      // console.log(this.question.language_apply_question(lang_id).options_data)
      this.question.language_apply_question(this.lang_id).options_data.push("")
    },
    deleteOption(index) {
      this.question.language_apply_question(this.lang_id).options_data.splice(index, 1)
    }
  },
  computed: {
    required() {
      if (this.question.is_required) {
        return "required"
      }
    }
  }
}

</script>

<style media="screen">
  .required h4::after {
    color: red;
    content: " *";
  }
  .required .v-radio label::after{
    content: ""
  }
</style>
