<template id="">
  <v-container >
    <v-row>
      <v-col cols="12" md="8" offset-md="2" >
        <h2 class="titleheader">{{help_article.title}}</h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="8" offset-md="2" >
        <vue-markdown class="help-markdown" :source="help_article.body"></vue-markdown>
      </v-col>
  </v-row>
</v-container>
</template>

<script>
import HelpArticle from '@/models/help_article'
import VueMarkdown from 'vue-markdown'

  export default {
    components: {
      VueMarkdown,
    },
    props: [
      "mykey"
    ],
    data(){
      return {
        help_article: new HelpArticle(this.mykey),
      }
    },
  }
</script>

<style scoped>
.titleheader {
  border-left: solid 8px;
  padding-left: 16px;
}
</style>

<style>
 .help-markdown img {
   margin-top: 12px;
   width: 100%;
 }
 .help-markdown h1,h2,h3,h4,h5 {
   margin-top: 16px;
   margin-bottom: 8px;
 }
 .help-markdown li {
   margin: 4px 0px;
 }
 .help-markdown .portrait-img {
   max-width: 328px;
 }
</style>
