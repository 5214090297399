<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" fullscreen scroolable max-width="300px">

      <template v-slot:activator="{ on }">
        <v-btn color="primary" dark v-on="on">{{btnName}}</v-btn>
      </template>

      <v-card>

        <v-app-bar fixed>
          <v-card-title>Select University</v-card-title>
          <v-spacer></v-spacer>
          <button @click="dialog = false" style="float: right; padding: 5px;"><v-icon>clear</v-icon></button>
          <!-- <v-btn color="blue darken-1" text @click="dialog = false">Close</v-btn> -->
        </v-app-bar>

        <v-divider style="margin-bottom: 80px;"></v-divider>
        <div class="mx-4 ">
          <v-list-item-content class="mb-5" @click="segue_to(user.university)" v-if="user.university">
            <p class="mb-4">Affiliated University</p>
            <v-list-item-title v-text="user.university.name_en" class="body-2"></v-list-item-title>
          </v-list-item-content>
          Select prefecture
          <v-autocomplete label="prefecture" :items="master.prefectures" item-text="name" item-value="id" v-model="prefecture_id"></v-autocomplete>
        </div>
        <v-list dense class="pt-0">
          <v-list-item v-for="university in universities">
            <v-list-item-content @click="segue_to(university)">
              <v-list-item-title v-text="university.name_en"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item >
            <v-list-item-content @click="$router.push('/');dialog=false;">
              <v-list-item-title>Do you want to see all events? click here.</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>

    </v-dialog>
  </v-row>
</template>

<script>
import axios from '@/store/axios'
import Master from '@/models/master'
import UniversitiesCollection from '@/models/universities_collection'

  export default {
    props: ["university"],
    data () {
      return {
        dialog: false,
        universities: null,
        prefecture_id: null,
        master: new Master(),
      }
    },
    methods: {
      segue_to(university){
        this.$router.push(`/u/${university.snake_name()}`)
        this.$store.dispatch('header_state/setUniversityByName', university.name_en)
        this.dialog = false

      },
    },
    computed: {
      btnName(){
        return this.university ? this.university.name_en : "Select University ▼"
      }
    },
    watch: {
      'dialog': function(val){
        if (this.university && val == true && this.universities == null) {
          this.prefecture_id = this.university.prefecture_id
          this.universities = new UniversitiesCollection(`/api/v1/masters/universities?prefecture_id=${this.university.prefecture_id}`)
        }
      },
      'prefecture_id': function(val){
        this.universities = new UniversitiesCollection(`/api/v1/masters/universities?prefecture_id=${this.prefecture_id}`)
      },
    },
    created(){
      this.$store.dispatch('user/setUniversity')
    }
  }
</script>

<style scoped>
  .v-list-item__title {
    cursor: pointer;
  }
</style>
