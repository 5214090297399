<template>
  <div>
    <v-container>
      <v-toolbar>
        <v-toolbar-title>
          {{ organization.language_organizations[0].name }}
        </v-toolbar-title>
        <div class="flex-grow-1"></div>
        <v-btn rounded @click="showModal = true" :color="is_following ? null : 'primary'">
          {{ is_following ? msg.following : msg.follow }}
        </v-btn>
      </v-toolbar>
    </v-container>

    <v-container v-if="showDescription || showPrefecture || showUniversity || hasSNSLink">
      <v-card>
        <v-list>
          <v-list-item v-if="showDescription">
            <v-list-item-content>
              <h4>{{ msg.description }}</h4>
              <p>
                {{ organization.language_organizations[0].discription }}
              </p>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="showPrefecture">
            <v-list-item-content>
              <h4>{{ msg.main_prefecture }}</h4>
              <p>
                {{ organization.prefectures[0].name }}
              </p>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="showUniversity">
            <v-list-item-content>
              <h4>{{ msg.university }}</h4>
              <p v-for="university in organization.universities" :key="university.name" v-text="university.name" />
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="hasSNSLink">
            <v-list-item-content>
              <h4>{{ msg.contact }}</h4>
              <v-container>
                <template v-for="link in links">
                  <v-list-item :key="link.url">
                    <v-list-item-icon>
                      <img :src="require(`@/assets/${link.name}.png`)" width="35px"/>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <a :href="link.url" target="_blank" v-text="truncate(link.url, 30)" />
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-container>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-container>

    <template>
      <v-container>
        <v-row>
          <v-col cols="12">
            <h3>{{ msg.future_events }}</h3>
          </v-col>
          <EventsListView :events="future_events" />
        </v-row>
      </v-container>

      <v-container>
        <v-row>
          <v-col cols="12">
            <h3>{{ msg.past_events }}</h3>
          </v-col>
          <EventsListView :events="past_events" />
        </v-row>
      </v-container>
    </template>
    <modal v-if="showModal && is_following" @close="showModal = false">
      <h3 slot="header" v-text="msg.you_can_unfollow" />
      <div slot="body">
        <v-row justify="center" align-content="center">
          <v-col cols="12" sm="6">
            <v-btn rounded block color="primary" @click="unfollow">{{ msg.yes_unfollow }}</v-btn>
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn rounded block @click="showModal = false">{{ msg.no_unfollow }}</v-btn>
          </v-col>
        </v-row>
      </div>
    </modal>
    <modal v-if="showModal && !is_following" @close="showModal = false">
      <h3 slot="header" v-text="msg.if_follow_then" />
      <div slot="body">
        <v-row justify="center" align-content="center">
          <v-col cols="12" sm="6">
            <v-btn rounded block color="primary" @click="follow">{{ msg.yes_follow }}</v-btn>
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn rounded block @click="showModal = false">{{ msg.no_follow }}</v-btn>
          </v-col>
        </v-row>
      </div>
    </modal>
  </div>
</template>

<script type="text/javascript">
import { isPast, isFuture } from "date-fns";
import axios from "@/store/axios";
import messages from "@/locales/organizations.json";
import Event from "@/models/event";
import EventsListView from "@/components/shared/views/events_list_view";
import Modal from "@/components/shared/views/modal";
import Organization from "@/models/organization";

export default {
  props: ["id"],
  components: {
    EventsListView,
    Modal,
  },
  data: function () {
    return {
      events: [],
      organization: new Organization(this.id),
      is_following: false,
      showModal: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    msg() {
      return messages[this.$store.state.user.language];
    },
    future_events() {
      return this.events.filter((value) => isFuture(value.start_at_date()))
    },
    past_events() {
      return this.events.filter((value) => isPast(value.start_at_date()))
    },
    links() {
      return [
        { name: "instagram", url: this.organization.link_instagram },
        { name: "wechat", url: this.organization.link_wechat },
        { name: "facebook", url: this.organization.link_facebook },
        { name: "line", url: this.organization.link_line },
        { name: "x", url: this.organization.link_x },
        { name: "official_site", url: this.organization.link_official_site },
      ].filter((link) => link.url);
    },
    showDescription() {
      return !!this.organization.language_organizations[0].discription;
    },
    showPrefecture() {
      return !!this.organization.prefectures?.length;
    },
    showUniversity() {
      return !!this.organization.universities?.length;
    },
    hasSNSLink() {
      return this.links.some((link) => link.url);
    },
  },
  created(){
    this.getEvents();
    this.getIsFollow();
  },
  methods: {
    async getEvents(){
      const { data } = await axios.get(`/api/v1/organizations/${this.id}/events`)
      const events = data
        .map((val) => {
          return new Event(val);
        })
        .sort((a,b) => a.start_at_date().getTime() - b.start_at_date().getTime());

      this.events = events;
    },
    async getIsFollow(){
      const { data } = await axios.get(`/api/v1/organizations/${this.id}/own_follow_state`)
      if(data === null){
        this.is_following = false;
        return;
      }

      this.is_following = !data.is_unfollow;
    },
    follow() {
      axios
        .put(`/api/v1/organizations/${this.id}/follow`)
        .then(() => {
          this.flash(this.msg.you_have_followed_this_organization, "success");
          this.showModal = false;
          this.getIsFollow();
        })
        .catch((err) => {
          var key = Object.keys(err.response.data)[0];
          this.flash(key + " " + err.response.data[key][0], "error");
        });
    },
    unfollow() {
      axios
        .put(`/api/v1/organizations/${this.id}/unfollow`)
        .then((resp) => {
          this.flash(this.msg.you_have_unfollowed_this_organization, "success");
          this.showModal = false;
          this.getIsFollow();
        })
        .catch((err) => {
          var key = Object.keys(err.response.data)[0];
          this.flash(key + " " + err.response.data[key][0], "error");
        });
    },
  },
};
</script>
