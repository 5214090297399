<template>
  <v-container fluid>
    <v-row>
      <v-text-field :label="msg.text_question" v-model="question.language_apply_question(lang_id).title"></v-text-field>
    </v-row>
    <v-row v-for="(option, index) in question.language_apply_question(lang_id).options_data">
      <v-checkbox disabled />
      <v-text-field type="text" :placeholder="msg.option_placeholder" v-model="question.language_apply_question(lang_id).options_data[index]" />
      <v-icon @click="deleteOption(index)" class="ml-5">clear</v-icon>
    </v-row>
    <v-row>
      <v-btn @click="addOption">{{msg.button_add_option}}  <v-icon>add</v-icon></v-btn>
    </v-row>
  </v-container>
</template>

<script>
import messages from '@/locales/event_form.json'

export default {
  props: [
    "question",
    "lang_id"
  ],
  methods: {
    addOption() {
      // console.log(this.question.language_apply_question(lang_id).options_data)
      this.question.language_apply_question(this.lang_id).options_data.push("")
    },
    deleteOption(index) {
      this.question.language_apply_question(this.lang_id).options_data.splice(index, 1)
    }
  },
  computed: {
    msg() { return messages[this.$store.state.user.language] },
  }
}

</script>
