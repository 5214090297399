<template>
    <v-container>
      <v-row>
        <v-col cols="12" md="10" offset-md="1">


          <!-- 大枠 -->
          <v-container>
            <v-row>
              <v-col cols="12" class="mb-10">
                <h2 class="my-4">{{msg.slogan}}</h2>
                <p class="float-none mt-2">{{msg.explanation}}</p>
                <v-btn to="about_us" text color="primary" outlined class="float-right mt-1">{{msg.more_about_us}}</v-btn>
              </v-col>
            </v-row>

            <v-card class="pa-5">
              <v-container>
                <h3 class="mb-4">{{msg.user_can_do}}</h3>
                <v-divider></v-divider>
                <v-row>
                  <v-col cols="12" md="6" class="mt-5 text-center justify-center">
                    <h3 class="mb-2 d-md-none text-center">{{msg.discover}}</h3>
                    <p class="mb-0 text-center"><v-icon color="primary" class="image-icon">search</v-icon></p>
                    <h3 class="d-none d-md-block text-center">{{msg.discover}}</h3>
                  </v-col>
                  <v-col cols="12" md="6" class="mt-5 text-center justify-center">
                    <h3 class="mb-2 d-md-none text-center">{{msg.apply}}</h3>
                    <p class="mb-0 text-center"><v-icon color="primary" class="image-icon">person_add</v-icon></p>
                    <h3 class="d-none d-md-block text-center">{{msg.apply}}</h3>
                  </v-col>
                  <v-col cols="12" md="6" class="mt-5">
                    <h3 class="mb-2 d-md-none text-center">{{msg.set_remind}}</h3>
                    <p class="mb-0 text-center"><v-icon color="primary" class="image-icon">event_available</v-icon></p>
                    <h3 class="d-none d-md-block text-center">{{msg.set_remind}}</h3>
                  </v-col>
                  <v-col cols="12" md="6" class="mt-5">
                    <h3 class="mb-2 d-md-none text-center">{{msg.receive_info}}</h3>
                    <p class="mb-0 text-center"><v-icon color="primary" class="image-icon">mail</v-icon></p>
                    <h3 class="d-none d-md-block text-center">{{msg.receive_info}}</h3>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>

            <v-row>
              <v-col cols="12" class="text-center my-10">
                <v-btn to="/signup" color="success" x-large class="font-weight-bold">{{msg.msg_to_sign_up}}</v-btn>
              </v-col>
            </v-row>
          </v-container>



        </v-col>
      </v-row>
    </v-container>
</template>
<script type="text/javascript">
import messages from '@/locales/about_univents.json'

export default{
  computed:{
    msg() { return messages[this.$store.state.user.language] },
  },
}
</script>

<style scoped>
.image-icon {
  font-size: 100px !important;
}

</style>
