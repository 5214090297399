<template>
  <div id="app">
    <v-container fluid>
      <v-row>
        <v-col cols="6">
          <h3>{{msg.event_create}}</h3>
          <p>{{msg.subdescription}}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-select v-model="event.language_events[0].language_id" :items="master.languages" item-text="name" item-value="id" :label="msg.main_language"></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <event-form ref="event_form" :event="event" :event_lang_id="event.language_events[0].language_id" @submit="submit"></event-form>
        </v-col>
      </v-row>
    </v-container>
    <v-container fluid>
      <v-btn color="success" @click="submit">{{msg.save}}</v-btn>
    </v-container>
  </div>
</template>

<script type="text/javascript">
import axios from '@/store/axios'
import EventForm from '@/components/shared/manage_organization_components/event_form.vue'
import Event from '@/models/event'
import Master from '@/models/master'
import messages from '@/locales/event_form.json'

export default {
  props: [
    "organization"
  ],
  components: {
    'event-form': EventForm
  },
  data: function () {
    return {
      event: new Event(),
      master: new Master()
    }
  },
  computed: {
    user() { return this.$store.state.user },
    form() { return this.$refs.event_form.$refs.form },
    msg() { return messages[this.$store.state.user.language] },
  },
  methods: {
    submit() {
      if ( this.form.validate() ) {
        axios.post(`/api/v1/organizations/${this.organization.id}/events/create`, {event: this.event.toPostedEvent()}).then( resp => {
          console.log(resp)
          this.$router.push(`/manage/organizations/${this.organization.id}/event_create/${resp.data.data.id}/select_lang`)
        }).catch( err => {
          var key = Object.keys(err.response.data)[0]
          this.flash(key+" "+err.response.data[key][0], "error")
        } )
      }
    }
  },
  // mounted() {
  //   console.log("mounted")
  //   axios.get('/api/v1/organizations/').then( resp => {
  //     console.log(resp)
  //     this.organizations = resp.data
  //   })
  // }
}

</script>
