<template>
  <div>
    <div :class="required">
      <h4 class="sub-title mb-3">{{ question.language_apply_question(lang_id).title }}</h4>
      <v-checkbox
        v-for="(option, index) in question.language_apply_question(lang_id).options_data"
        class="mt-2" v-model="answers[question.id]"
        :label="question.language_apply_question(lang_id).options_data[index]"
        v-bind:value="question.language_apply_question(lang_id).options_data[index]"
        :rules="rules"
        :hide-details="index+1!= question.language_apply_question(lang_id).options_data.length">
      </v-checkbox>
    </div>
  </div>
</template>

<script>

export default {
  props: [
    "question",
    "answers",
    'lang_id'
  ],
  data() {
    return {
      rules: [ v => ( !this.question.is_required || this.answers[this.question.id].length > 0 ) || "This is a required question" ],
    }
  },
  computed: {
    required() {
      if (this.question.is_required) {
        return "required"
      }
    }
  }
}

</script>

<style media="screen">
  .required .v-input--checkbox label::after{
    content: ""
  }
</style>
