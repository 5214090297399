<template>
  <v-form ref="form">
    <v-container fluid>
      <template v-if="event.id">
        <v-row>
          <h4 class="mr-3">{{msg.thumbnail}}</h4>
        </v-row>
        <v-row class="mb-3">
          <VCol cols="12">
            <ImageUpload :value="event.thumbnail_url" :getPresignedUrl="getPresignedUrl" @success="s3UploadSuccess" @error="s3UploadError"></ImageUpload>
          </VCol>
        </v-row>
      </template>
      <v-row>
        <h4 class="mr-3">{{msg.event_info}}</h4>
      </v-row>
      <v-row>
        <v-text-field :label="msg.event_title" class="required" :rules="titleRules" v-model="event.language_event(event_lang_id).title"></v-text-field>
      </v-row>
      <v-row>
        <v-checkbox v-model="event.is_online" :label="msg.held_online"></v-checkbox>
      </v-row>
      <v-row v-if="!event.is_online">
        <v-text-field :label="msg.venue_name" class="required" :rules="placeNameRules" v-model="event.language_event(event_lang_id).place_name"></v-text-field>
      </v-row>
      <v-row v-if="!event.is_online">
        <v-autocomplete :label="msg.prefecture" :items="master.prefectures" item-text="name" item-value="id" class="required" :rules="prefectureRules" v-model="event.prefecture_id">
        </v-autocomplete>
      </v-row>
      <v-row v-if="!event.is_online">
        <v-text-field :label="msg.address" v-model="event.language_event(event_lang_id).address"></v-text-field>
      </v-row>
      <v-row>
        <v-select :label="msg.type" :items="master.event_formats" item-text="name" item-value="id" class="required" :rules="formatRules" v-model="event.event_format_id">
        </v-select>
      </v-row>
      <v-row>
        <v-select :label="msg.purpose" :items="master.event_purposes" item-text="name" item-value="id" class="required" :rules="purposeRules" v-model="event.event_purpose_id">
        </v-select>
      </v-row>
      <v-row class="required">
        <v-datetime-picker :label="msg.event_start_time" class="required" :rules="startAtRules" v-model="event.start_at" format="YYYY-MM-DD HH:mm" ></v-datetime-picker>
      </v-row>
      <v-row>
        <v-datetime-picker :label="msg.event_end_time" v-model="event.end_at" format="YYYY-MM-DD HH:mm" ></v-datetime-picker>
      </v-row>
      <v-row>
        <v-datetime-picker :label="msg.event_recieption_start_time" v-model="event.recieption_start_at" format="YYYY-MM-DD HH:mm" ></v-datetime-picker>
      </v-row>
      <v-row>
        <v-text-field class="required" :rules="maxParticipantRules" v-model="event.max_participant_num" type="number" :label="msg.capacity"></v-text-field>
      </v-row>
      <v-row>
        <v-select :label="msg.spoken_language" :items="master.languages" item-text="name" item-value="id" multiple class="required" :rules="usedLanguageRules" v-model="event.used_language_ids">
        </v-select>
      </v-row>
      <v-row>
        <v-textarea :label="msg.description" auto-grow v-model="event.language_event(event_lang_id).discription"></v-textarea>
      </v-row>
      <v-row>
        <v-datetime-picker :label="msg.apply_due" v-model="event.apply_due" format="YYYY-MM-DD HH:mm" ></v-datetime-picker>
      </v-row>
      <v-row>
        <v-switch :label="msg.release" v-model="event.is_published"></v-switch>
      </v-row>

      <template v-if="organization.is_hf">
        <v-row>
          <h3>HFが登録するとき専用カラム</h3>
        </v-row>
        <v-row>
          <v-text-field label="イベント開催者名" auto-grow v-model="event.language_event(event_lang_id).organization_name"></v-text-field>
        </v-row>
        <v-row>
          <v-textarea label="イベントのURL" auto-grow v-model="event.detail_url"></v-textarea>
        </v-row>
      </template>


      <!-- ターゲット -->
      <v-row>
        <h3 class="mt-5" style="width: 100%;">{{msg.target_event}}</h3>
        <p>{{msg.target_event_subdescription}}</p>
      </v-row>
      <v-row>
        <p>
          <!-- 月初のターゲットへの告知に使われます -->
        </p>
      </v-row>
      <v-row>
        <v-checkbox v-model="is_only_for_students" :label="msg.is_only_for_students"></v-checkbox>
      </v-row>
      <v-row>
        <v-checkbox v-model="event.is_shown_only_organization_universities" :label="msg.is_only_for_our_university_students"></v-checkbox>
      </v-row>
      <!-- <v-row>
        <v-select :label="msg.target_event_occupation" :items="master.occupations" item-text="name" item-value="id" multiple v-model="event.target_occupation_ids">
        </v-select>
      </v-row>
      <v-row>
        <v-select :label="msg.prefecture" :items="master.prefectures" item-text="name" item-value="id" multiple v-model="event.target_prefecture_ids" :rules="targetPrefectureRules" class="required">
        </v-select>
      </v-row>
      <v-row>
        <v-select :label="msg.target_event_university" :items="target_university_options" item-text="name" item-value="id" multiple v-model="event.target_university_ids" no-data-text="You can choose universities only in target prefectures">
        </v-select>
      </v-row>
      <v-row>
        <p>You can not choose universities except in target prefectures</p><br>
      </v-row> -->
      <!-- <v-row>
        <v-select :label="msg.target_event_department" :items="master.department_categories" item-text="name" item-value="id" multiple v-model="event.target_department_category_ids">
        </v-select>
      </v-row>
      <v-row>
        <v-select :label="msg.target_event_country" :items="master.countries" item-text="name" item-value="id" multiple v-model="event.target_country_ids">
        </v-select>
      </v-row> -->
    </v-container>


    <v-container fluid>
      <v-row>
        <h3 class="mb-3">{{msg.title_questionnaire}}</h3>
      </v-row>
      <v-row>
        <p>
          {{ msg.questionnaire_subdescription }}
        </p>
      </v-row>
      <!-- 質問項目s -->
      <v-row v-for="question in showed_apply_questions"  :key="question.id" class="mb-5">
        <v-card>
          <v-container fluid>
            <!-- カードの中身 -->
            <v-row class="pt-4 px-4">
              <v-select :label="msg.question_type" :items="master.question_types" item-text="name" item-value="id" v-model="question.question_type_id">
              </v-select>
            </v-row>
            <v-row class="pb-4 px-4">
              <aq-radio-button :question="question" :lang_id="event_lang_id" v-if="question.question_type_id==1"></aq-radio-button>
              <aq-check-box :question="question" :lang_id="event_lang_id" v-if="question.question_type_id==2"></aq-check-box>
              <aq-long-text :question="question" :lang_id="event_lang_id" v-if="question.question_type_id==3"></aq-long-text>
              <aq-short-text :question="question" :lang_id="event_lang_id" v-if="question.question_type_id==4"></aq-short-text>
            </v-row>

          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-switch v-model="question.is_required" :label="msg.required" class="mr-2"></v-switch>
            <v-btn class="ml-3" @click="destroyQuestion(question)" color="error">{{msg.delete}}<v-icon>delete</v-icon></v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
      <v-row>
        <v-btn @click="addQuestion">{{msg.button_add_question}}<v-icon>add_circle_outline</v-icon></v-btn>
      </v-row>
    </v-container>




    <!-- <label>参加費</label>
    <input type="number" v-model="event.fee" placeholder="例) 名古屋大学"><br> -->
    <!-- <label>タイムテーブル</label>
    <input type="text" v-model="event.language_event(event_lang_id).place_name" placeholder="例) 名古屋大学"><br> -->
    <!-- <label>イベント対象者</label> -->
    <!-- <label>その他</label>
    <input type="text" v-model="event.language_event(event_lang_id)ace_name" placeholder="例) 名古屋大学"><br> -->



  </v-form>
</template>

<script type="text/javascript">
import axios from '@/store/axios'
import AqRadioButton from '@/components/shared/manage_organization_components/event_form_cmpnts/radiobutton.vue'
import AqCheckBox from '@/components/shared/manage_organization_components/event_form_cmpnts/checkbox.vue'
import AqLongText from '@/components/shared/manage_organization_components/event_form_cmpnts/longtext.vue'
import AqShortText from '@/components/shared/manage_organization_components/event_form_cmpnts/shorttext.vue'
import DatetimePicker from 'vuetify-datetime-picker'
import Master from '@/models/master'
import messages from '@/locales/event_form.json'
import Organization from '@/models/organization'
// import 'vuetify-datetime-picker/src/stylus/main.styl'


export default {
  props: [
    "event",
    "event_lang_id"
  ],
  components: {
    'aq-radio-button': AqRadioButton,
    'aq-check-box': AqCheckBox,
    'aq-long-text': AqLongText,
    'aq-short-text': AqShortText,
    // 'v-datetime-picker': DatetimePicker
    ImageUpload: () => import('@/components/shared/manage_organization_components/image_upload.vue'),
  },
  data: function () {
    return {
      master: new Master(),
      have_validated: false,
      titleRules: [ v => (!!v) || "Title can't be blank" ],
      placeNameRules: [ v => (!!v) || "Place Name can't be blank" ],
      prefectureRules: [ v => (!!v) || "Prefecture is required" ],
      formatRules: [ v => (!!v) || "Format is required" ],
      purposeRules: [ v => (!!v) || "Purpose is required" ],
      startAtRules: [ v => (!!v) || "Start Time is required" ],
      maxParticipantRules: [ v => (!!v) || "Max Participants is required" ],
      usedLanguageRules: [ v => (!!v && v.length >= 1) || "Spoken Language is required" ],
      targetPrefectureRules: [ v => (!!v && v.length >= 1) || "Target Prefecture is required" ],
      organization: new Organization(this.$route.params.organization_id),
      awss3: {
        signingURL: `/api/v1/s3/organizations/${this.event.organization_id}/events/${this.event.id}/header`,
        params : {},
        sendFileToServer : true,
        withCredentials: false
      },
    }
  },
  methods: {
    addQuestion() {
      console.log("addQuestion")
      this.event.add_apply_question()
    },
    destroyQuestion(question) {
      question._destroy = true
    },
    isValid() {
      this.have_validated = true
      if (
        this.event.prefecture_id == null &&
        this.event.event_purpose_id == null &&
        this.event.event_format_id == null
      ){
        return false;
      }
      return true
    },
    async getPresignedUrl(filename){
      const resp = await axios.get(
        `/api/v1/s3/organizations/${this.event.organization_id}/events/${this.event.id}/header`,
        {
          params: { filename }
        }
      )
      return resp.data
    },
    s3UploadError (e) {
    },
    s3UploadSuccess(url){
      this.event.thumbnail_url = url
    },
  },
  computed: {
    showed_apply_questions(){
      return this.event.apply_questions.filter( aq => { return !aq._destroy } )
    },
    msg() { return messages[this.$store.state.user.language] },
    target_university_options(){
      return this.master.universities.filter( univ => {
        return this.event.target_prefecture_ids.indexOf(univ.prefecture_id) >= 0
      } )
    },
    is_only_for_students: {
      get() {
        return this.event.target_occupation_ids.length != 0
      },
      set(value) {
        if (value == true) {
          this.event.target_occupation_ids = [1] //大学生のみ
        } else {
          this.event.target_occupation_ids = []
        }
      },
    }
  },
  watch: {
    "event.prefecture_id": function(prefecture_id){
      if (this.event.target_prefecture_ids.length == 0) {
        this.event.target_prefecture_ids.push(prefecture_id)
      }
    }
  }
}

</script>

<style media="screen">
.v-time-picker-custom .v-picker__title{
  height: 84px;
  padding-top: 10px;
}
.required label::after {
  color: red;
  content: " *";
}
.v-picker__body {
  margin: initial;
}
</style>
