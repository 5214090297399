<template id="">
  <div class="grey lighten-5">
      <v-container class="pt-10">
        <v-btn v-if="user.is_uniteam" :to="`/uniteamanage/help_articles/${mykey}/edit`">編集</v-btn>
        <v-row>
          <v-col cols="12" md="10" offset-md="2" >
            <h1 style="">Support Pages</h1>
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-container>
      <!-- 再レンダリングのためにコンポーネント化 -->
      <show-content :mykey="mykey" :key="mykey"></show-content>
  </div>
</template>

<script type="text/javascript">
import ShowContent from './show_content'

  export default {
    components: {
      ShowContent
    },
    props: [
      "mykey"
    ],
  }
</script>

<style media="screen" scoped>
.help-article-header{
  height: 144px;
  margin-top: -8px;
}
.help-article-header h1{
  margin: 30px 0px 0px 0px;
}

.wide-through {
  width: 100vw;
  max-width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
}
</style>
