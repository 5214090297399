<template>
  <div id="app">
    <v-snackbar v-model="snackbar" :timeout="1500" top color="success">
      {{snackbarText}}
    </v-snackbar>
    <v-container fluid>
      <v-row>

        <v-col cols="6">
          <h3 class="mb-3">{{ master.language_find(organization.main_language_organization()?.language_id)?.name }}</h3>
          <organization-form :organization="organization" :organization_lang_id="organization.main_language_organization()?.language_id" @submit="submit" :master="master"></organization-form>
        </v-col>

        <v-col cols="6">
          <h3 class="mb-3" v-if="organization.second_language_organization()">{{ master.language_find(organization.second_language_organization().language_id)?.name }}</h3>
          <organization-form v-if="organization.second_language_organization()" :organization="organization" :organization_lang_id="organization.second_language_organization().language_id" @submit="submit" :master="master"></organization-form>
          <no-second-lang v-if="!organization.second_language_organization()" :organization="organization"></no-second-lang>
        </v-col>

      </v-row>
    </v-container>

    <div style="min-height: 300px">
      <v-btn color="success" absolute right @click="submit">登録</v-btn>
    </div>

  </div>
</template>

<script type="text/javascript">
import axios from '@/store/axios'
import Organization from '@/models/organization'
import OrganizationForm from '@/components/shared/organization_form'
import NoSecondLang from '@/components/shared/manage_organization_components/no_second_lang'
import Master from '@/models/master'

export default {
  props: [
    "organization",
  ],
  components: {
    'organization-form': OrganizationForm,
    'no-second-lang': NoSecondLang
  },
  data: function () {
    return {
      'snackbar': false,
      'snackbarText': null,
      'master': new Master()
    }
  },
  computed: {
    user() { return this.$store.state.user },
  },
  methods: {
    submit() {
      axios.put(`/api/v1/organizations/${this.organization.id}/update`, {organization: this.organization.toPostedOrganization()}).then( resp => {
        console.log(resp)
        this.snackbarText = "保存されました"
        this.snackbar = true
      })
    }
  },
  mounted() {
  }
}

</script>
