<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <h3 class="mb-3">{{ master.language_find(event.main_language_event().language_id).name }}</h3>
        <event-form ref="main_form" :event="event" :event_lang_id="event.main_language_event().language_id" @submit="submit"></event-form>
      </v-col>
      <v-col cols="6">
        <h3 class="mb-3">{{ master.language_find(event.second_language_event().language_id).name }}</h3>
        <event-form ref="second_form" :event="event" :event_lang_id="event.second_language_event().language_id" @submit="submit"></event-form>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-btn color="success" block @click="submit" width="200px">保存</v-btn>
    </v-row>
  </v-container>
</template>

<script type="text/javascript">
import axios from '@/store/axios'
import EventForm from '@/components/shared/manage_organization_components/event_form.vue'
import Event from '@/models/event'
import Master from '@/models/master'

export default {
  props: [
    "organization_id",
    "event_id"
  ],
  components: {
    'event-form': EventForm
  },
  data: function () {
    return {
      'event': new Event(this.event_id),
      'master': new Master(),
    }
  },
  computed: {
    user() { return this.$store.state.user },
    main_form() {return this.$refs.main_form.$refs.form},
    second_form() {return this.$refs.second_form.$refs.form},
  },
  methods: {
    submit() {
      if ( this.main_form.validate() && this.second_form.validate() ){
        axios.put(`/api/v1/events/${this.event_id}/update`, {event: this.event.toPostedEvent()}).then( resp => {
          console.log(resp)
          this.$router.push(`/events/${this.event_id}`)
        })
      }else{
        // secondフォームのvalidationを表示させる
        second_form.validate()
      }
    }
  },
  mounted() {
  }
}

</script>
