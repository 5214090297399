<template>
  <div id="app">
    <v-snackbar v-model="snackbar" :timeout="0" top color="error">
      You might already register... Please Login here <v-btn to="/login" class="black--text">Login</v-btn>
    </v-snackbar>
    <v-layout wrap row justify-center>
      <v-flex xs9 md6>
        <h2 class="my-5 text-left">Sign Up</h2>
        <p style="font-weight: bold;">
          ✔︎ {{ msg.good_description1 }} <br>
          ✔︎ {{ msg.good_description2 }} <br>
          ✔︎ {{ msg.good_description3 }} <br>
        </p>
        <sign-up-form ref="sign_up_form" :user_data="user_data" :input_disabled='input_disabled'></sign-up-form>
      </v-flex>
    </v-layout>
    <v-layout wrap row justify-center class="mb-5">
      <v-flex xs9 md6>
        <h4>{{msg.terms_of_service}}</h4>
        <terms-of-service></terms-of-service>
      </v-flex>
    </v-layout>
    <v-layout wrap row justify-center>
      <v-flex xs9 md6>
        <v-btn block color="success" class="mr-4" @click="submit">{{msg.submit}}</v-btn>
      </v-flex>
    </v-layout>
    <!-- <v-container class="marginTop9">
      <v-layout align-center justify-center>
        <v-form ref="form" lazy-validation>
          <v-text-field v-model="name" :counter="15" :rules="nameRules" label="Name"></v-text-field>
          <v-text-field :disabled="input_disabled" v-model="user_data.email" placeholder="email@example.com" :rules="emailRules"  label="E-mail" required></v-text-field>
          <v-text-field :disabled="input_disabled" v-model="user_data.password" type="password" label="Password" required ></v-text-field>
          <v-text-field :disabled="input_disabled" v-model="user_data.password_confirmation" type="password" label="Password Confirmation" required ></v-text-field>
          <v-btn width="350px" block color="success" class="mr-4" @click="submit">Submit</v-btn>
        </v-form>
      </v-layout>
    </v-container> -->
  </div>
</template>

<script>
import axios from "@/store/axios"
import SignUpForm from '@/components/shared/sign_up_form'
import TermsOfService from '@/components/shared/terms_of_service'
import messages from '@/locales/sign_up_form.json'
import top_messages from '@/locales/top.json'

export default {
  components: {
    'sign-up-form':SignUpForm,
    'terms-of-service': TermsOfService
  },
  data: function () {
    return {
      user_data: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        password_confirmation: "",
        country_id: null,
        occupation_id: null,
        university_id: null,
        department_category_id: null,
        graduation_year: null,
        graduation_month: null,
        confirm_success_url: "/home"
      },
      'snackbar': false,
    }
  },
  computed: {
    form() { return this.$refs.sign_up_form.$refs.form },
    msg() { return Object.assign(messages[this.$store.state.user.language], top_messages[this.$store.state.user.language]) },
  },
  methods: {
    submit: function () {
      if ( this.form.validate() ) {
        axios.post('/api/v1/auth/', this.user_data).then( resp => {
          this.$store.dispatch('auth/create', resp.headers)
          this.$router.push('/manage');
        }).catch( resp => {
          console.log(resp)
          if (resp.response.data.errors.full_messages.indexOf("Email has already been taken") >= 0){
            this.snackbar = true
          }
        })
      }
    }
  }
}
</script>

<style scoped>
/* p {
  font-size: 2em;
  text-align: center;
} */
</style>
