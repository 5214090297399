<template>
  <div id="app">

    <v-container class="marginTop9" v-if="!have_success_reset_request">
      <v-row justify="center">
        <v-col cols="6">
          <h2>Reset Password</h2>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="6">
          <v-text-field v-model="user_data.email" label="Email"></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="6">
          <v-btn color="success" block @click="submit" :disabled="sending">{{btn_text}}</v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-layout wrap row justify-center v-if="have_success_reset_request" class="marginTop9">
      <v-flex xs9 md6>
        <h2>Password Reset</h2>
        <p>
          An email to reset your password is sent to {{ user_data.email }}. Please check your mailbox.<br/>
          When you don't receive email, you might sign up with wrong address. <br>
          Please signup once again.
        </p>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "@/store/axios"

export default {
  data: function () {
    return {
      user_data: {
        email: "",
        //redirect_url: "http://localhost:3000/#/password" //development
        redirect_url: "https://www.univents.jp/#/password" //production
      },
      have_success_reset_request: false,
      input_disabled: false,
      sending: false,
    }
  },
  computed: {
    btn_text() {
      if (!this.sending) { return "Send Reset Password Mail" }
      if (this.sending) { return "Sending request..." }
    }
  },
  methods: {
    submit: function () {
      this.input_disabled = true
      this.sending = true
      axios.post('/api/v1/auth/password', this.user_data).then( resp => {
        this.have_success_reset_request = true
      }).catch( resp => {
        this.have_success_reset_request = true
        console.log(resp);
      } )
    }
  }
}
</script>

<style scoped>
</style>
