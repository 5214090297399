<template>
  <v-snackbar v-model="snackbar.isShown" :color="snackbar.color" :timeout="5000" top style="z-index: 10000;">
    <template v-if="snackbar.type == 'text'"> {{ snackbar.text }} </template>
    <template v-if="snackbar.type == 'list'">
      <ul>
        <li v-for="text in snackbar.texts" :key="text">
          {{ text }}
        </li>
      </ul>
    </template>
    <template v-slot:action="{ attrs }">
      <v-btn dark text v-bind="attrs" @click="snackbar.isShown = false">
        Close
      </v-btn>
    </template>
  </v-snackbar>
</template>


<script type="text/javascript">
export default {
  data() {
    return {
      snackbar: {
        isShown: false,
        color: "error",
        type: "text",
        text: "",
        texts: []
      },
    }
  },
  computed: {
    isShown() { return this.snackbar.isShown }
  },
  methods: {
    showErrors(errors) {
      this.snackbar.isShown = true
      this.snackbar.color = "error"
      this.snackbar.type = "list"
      this.snackbar.texts = errors
    },
    showSuccess(text) {
      this.snackbar.isShown = true
      this.snackbar.color = "success"
      this.snackbar.type = "text"
      this.snackbar.text = text
    },
    showError(text) {
      this.snackbar.isShown = true
      this.snackbar.color = "error"
      this.snackbar.type = "text"
      this.snackbar.text = text
    },
  },
  watch: {
    isShown(val) {
      if (val == true) {
        this.snackbar.isShown = false
        this.snackbar.isShown = true
      }
    }
  }
}
</script>
